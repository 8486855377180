import React, { Fragment, useState, useEffect } from "react";
import { useStore } from 'react-hookstore';
import { withTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider'
//import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
//import IconButton from '@material-ui/core/IconButton';
//import PhotoCamera from '@material-ui/icons/PhotoCamera';
//import CloudUploadIcon from '@material-ui/icons/CloudUpload';
//import Button from '@material-ui/core/Button';
import BotonPdf from './../../components/botones/BotonPdf.js'
//import BotonValidacion from './../../components/botones/BotonValidacion.js'

import 'date-fns';
import esLocale from "date-fns/locale/es";
import euLocale from "date-fns/locale/eu";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

/*
import { Button }        from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox'
import Dialog            from '@material-ui/core/Dialog';
import DialogActions     from '@material-ui/core/DialogActions';
import DialogContent     from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle       from '@material-ui/core/DialogTitle';
import InputLabel        from '@material-ui/core/InputLabel';
import TextField         from '@material-ui/core/TextField'
import Tooltip           from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
*/
//import { dateTimePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";

// por ahora no imprimimos
//import ReactToPrint from 'react-to-print'
//import ComponentToPrint from './ComponentToPrint.js'

import ThemeGeroa from './../../components/ThemeGeroa.js'
//import SelectorFecha    from './../../components/capturas/SelectorFecha'

import auth from './../../apis/auth-helper.js'
import { listPedFacCli } from './../../apis/api-pedido.js'
//import {listFacturasCliente} from './../../apis/api-factura.js'

import config from './../../config/config.js'

import { NumeroEsp, FechaDdMmAaaa, FechaAaaaMmDd } from './../../config/funcionesPropias.js'
//import { PrecioCliente } from './../../config/funcionesParticulares.js'








const useStyles = makeStyles(theme => ({
    /// color mas claro #caae6b
    ///color Cabecera #BD9A46
    ///color mas oscuro #846b31
    registro: {
        marginTop: '20px',
        minHeight: '65vh'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: -10,
        width: 70
    },
    boton: {
        color: '#FFF',
        backgroundColor: '#1b5e20',
        textTransform: "none",
        marginRight: '10px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "#4caf50",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
    },
    cabTitulo: {
        display: "flex",
        flexFlow: "row",
        justifyContent: 'flex-start',
        alignItems: 'center', // alinea el contenido del Grid
        //border: "1px solid magenta",
    },
    cabTituloLeft: {
        //border: "2px solid blue",
    },
    cabTituloRight: {
        textAlign: 'right', // Alinea el boton a la Dcha.
        //border: "2px solid gray",
    },
    cabFechas: {
        justifyContent: 'space-between', // Alin. Hor.: 'space-between', 'space-around', 'flex-start', 'flex-end'
        alignItems: 'center', // Alin. contenido del Grid Vert.: flex-start, center, flex-end, 
        //border: "1px solid magenta",
    },
    estados: {
        textAlign: 'left', // Alinea el contenido del boton a la Izda.
    },
    menuItem: {
        fontSize: 13,
        color: '#757575'
    },
}));

let pedidos = []
//let facturas = []

const MisFacturas = ({ t }) => {
    const classes = useStyles();
    const jwt = auth.isAuthenticated()

    const [valuesGlobales,] = useStore('DatosGlobales');
    const [pedidosFiltrados, setPedidosFiltrados] = useState([])

    const [mensaje, setMensaje] = useState("")
    const [carga, setCarga] = useState(true)
    // Fechas. //
    let hoy = new Date()
    const [fechas, setFechas] = useState({
        Desde: new Date(hoy.getTime() - (90 * 24 * 60 * 60 * 1000)),
        Hasta: hoy
    })
    // Apaga/Enciende botón exportación. //
    //const [apagadoPdf, setApagadoPdf] = useState(false)

    useEffect(() => {
        if (carga) {
            //console.time("carga")
            leePedidos()
            //console.timeEnd("carga")
        }
    })


    async function leePedidos() {
        // Ya estamos cargando. //
        //console.log('ccc = ', valuesGlobales)
        setCarga(false)
        // Poner mensaje en pantalla. //
        setMensaje(t("General.Cargando"))
        try {
            // Cargar sólo los pedidos del cliente //
            pedidos = await listPedFacCli({ cliente: valuesGlobales.codigoUsuario }, { t: jwt.token })
            //console.log('tras la llamada = ', pedidos)

            // Cargar solo las facturas del productor
            //facturas = await listFacturasCliente({codigoCliente: valuesGlobales.codigoUsuario}, {t: jwt.token})
        } catch (e) {
            console.log("Error en Pedidos/Facturas del Cliente: " + e)
        }
        // Filtrar líneas. //
        let pedFil = filtraLineas(FechaAaaaMmDd(fechas.Desde), FechaAaaaMmDd(fechas.Hasta))
        // Ganchos para Repintar. //
        setMensaje("")
        setPedidosFiltrados(pedFil)
        //console.log('tras el filtro = ', pedFil)
    }


    function filtraLineas(df, hf) {
        let total = 0
        let importePdf = 0
        //let precioCliente = 0
        //
        let misPedidos = pedidos.filter(e => e.fechaFactura !== undefined)
        //console.log('tras el primer filtro = ', misPedidos)

        misPedidos = misPedidos.filter(e => e.fechaFactura.substr(0, 10) >= df && e.fechaFactura.substr(0, 10) <= hf)



        // Ordenar array Descendente por Fecha+Numero Factura. //
        misPedidos.sort(function (a, b) {
            if (a.fechaFactura + a.numero < b.fechaFactura + b.numero) {
                return 1
            }
            if (a.fechaFactura + a.numero > b.fechaFactura + b.numero) {
                return -1
            }
            return 0
        })
        //

        misPedidos.forEach(pedido => {
            total = 0
            pedido.products.forEach(producto => {
                if (producto.cantidadServida !== "-1") {
                    //precioCliente = PrecioCliente(producto.price, producto.product.iva, valuesGlobales.ivaUsuario)
                    //console.log('precio = ', producto.price, '  iva = ',producto.product.iva, '  ivausuario = ', valuesGlobales.ivaUsuario, ' PrecioCliente = ', precioCliente, ' cantidad = ', producto.cantidadServida)
                    //console.log('pedido = ', pedido.numero, '  producto = ', producto.product.codigo, ' precio = ',producto.price, ' iva = (', producto.product.iva, ') ',PrecioCliente(100, producto.product.iva, valuesGlobales.ivaUsuario),' precio con iva = ', precioCliente)
                    //console.log('aaa ', producto.cantidadServida, ' ', producto.price + ' ', (1 + producto.iva / 100))
                    total = total + (producto.cantidadServida * producto.price * (1 + producto.iva / 100))
                }
            })
            // Añadir Gastos de Reparto. //
            pedido.importeTotal = total + Number(pedido.gastos)
            //
            importePdf += pedido.importeTotal
            //
            if (pedido.pdf) {
                pedido.importePdf = importePdf
                importePdf = 0
            }
            //
            return null
        })



        console.log('pedidos sumados ', misPedidos)
        return misPedidos
    }


    ////////////////////////////////
    // Guardar cambios de Fechas. //
    ////////////////////////////////
    const handleFechas = (campo) => (date) => {
        let desde = fechas.Desde
        let hasta = fechas.Hasta
        // Si cambia Desde Fecha. //
        if (campo === 'Desde') {
            desde = date
            setFechas({ ...fechas, Desde: date })
        }
        // Si cambia Hasta Fecha. //
        if (campo === 'Hasta') {
            hasta = date
            setFechas({ ...fechas, Hasta: date })
        }
        // Generar array a pintar. //
        let pedFil = filtraLineas(FechaAaaaMmDd(desde), FechaAaaaMmDd(hasta))
        // Gancho a pantalla. //
        setPedidosFiltrados(pedFil)
    }

    //console.log('pedidosfiltrados = ', pedidosFiltrados)

    const lineasPedidos = (
        pedidosFiltrados.length !== 0 ?
            <Fragment>
                {pedidosFiltrados.map((pedido, i) => (
                    <Fragment key={i}>
                        {i === 0 ?
                            <Fragment>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: '#1565c0', fontSize: 14, fontWeight: 500 }}>
                                            {t("MisFacturas.FechaFactura")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography style={{ color: '#1565c0', fontSize: 14, fontWeight: 500 }}>
                                            {t("MisFacturas.Pedido")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: '#1565c0', fontSize: 14, fontWeight: 500 }}>
                                            {t("MisFacturas.FechaEntrega")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} align={'right'}>
                                        <Typography style={{ color: '#1565c0', fontSize: 14, fontWeight: 500 }}>
                                            {t("MisFacturas.ImporteTotal")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} align={'right'}>
                                    </Grid>
                                    <Grid item xs={1} align={'right'}>
                                        <Typography style={{ color: '#1565c0', fontSize: 14, fontWeight: 500 }}>
                                            {t("MisFacturas.Factura")}
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Divider />
                            </Fragment>
                            :
                            <Fragment />
                        }
                        <Grid container>
                            <Grid item xs={2}>
                                <Typography style={{ color: '#1565c0', fontSize: 13, fontWeight: 500 }}>
                                    {FechaDdMmAaaa(new Date(pedido.fechaFactura))}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ color: '#1565c0', fontSize: 13, fontWeight: 500 }}>
                                    {pedido.numero}
                                    <Typography style={{ color: '#1565c0', fontSize: 12, fontWeight: 500 }} display="inline">

                                        {" (" + pedido.fPago + ")"}
                                    </Typography>

                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{ color: '#1565c0', fontSize: 13, fontWeight: 500 }}>
                                    {FechaDdMmAaaa(new Date(pedido.fechaEntrega))}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} align={'right'}>
                                <Typography style={{ color: '#1565c0', fontSize: 13, fontWeight: 500 }}>
                                    {NumeroEsp(pedido.importeTotal, 2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} align={'right'}>
                                <Typography style={{ color: '#1565c0', fontSize: 13, fontWeight: 500 }}>
                                    {pedido.pdf ? NumeroEsp(pedido.importePdf, 2) : ""}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} align={'right'}>
                                {(pedido.fechaFactura.substr(0, 10) > '2020-11-18') && (pedido.pdf) ?
                                    <BotonPdf
                                        fichero={config.rutaCarpetaFicherosparaWebs + config.rutaPdfCliente + '/' + pedido.numero + '.pdf'}
                                    />
                                    :
                                    <Fragment />
                                }
                            </Grid>
                        </Grid>

                    </Fragment>
                ))}
            </Fragment>
            :
            mensaje === "" ?
                <Fragment>
                    <br />
                    <Typography style={{ color: '#846b31', fontSize: 16, fontWeight: 500 }} display="inline" >
                        {t("MisFacturas.NoHayPed")}
                    </Typography>
                </Fragment>
                :
                <Fragment />
    )




    const cabeceraFacturas = (
        <Fragment>
            <Grid container className={classes.cabTitulo}>
                <Grid item className={classes.cabTituloLeft} xs={6}>
                    <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600 }} display="inline">
                        {t("MisFacturas.Facturas")}
                    </Typography>
                </Grid>
                <br />
            </Grid>
            <Grid container className={classes.cabFechas}>
                <MuiPickersUtilsProvider locale={valuesGlobales.idioma === "Es" ? esLocale : euLocale} utils={DateFnsUtils}>
                    <Grid item>
                        <KeyboardDatePicker
                            disableToolbar
                            id="desdeFecha"
                            margin="normal"
                            style={{ width: "160px" }}
                            //variant="inline"
                            format="dd-MM-yyyy"
                            label={t("MisFacturas.DesdeFecha")}
                            value={fechas.Desde}
                            onChange={handleFechas("Desde")}
                            invalidDateMessage={t("General.FechaError")}
                            okLabel={t("General.Aceptar")}
                            cancelLabel={t("General.Cancelar")}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <KeyboardDatePicker
                            disableToolbar
                            id="hastaFecha"
                            margin="normal"
                            style={{ width: "160px" }}
                            //variant="inline"
                            format="dd-MM-yyyy"
                            label={t("MisFacturas.HastaFecha")}
                            value={fechas.Hasta}
                            onChange={handleFechas("Hasta")}
                            invalidDateMessage={t("General.FechaError")}
                            okLabel={t("General.Aceptar")}
                            cancelLabel={t("General.Cancelar")}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
                <Grid item>
                </Grid>
            </Grid>

        </Fragment>
    )


    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Fragment>
                <Container maxWidth="md" className={classes.registro}>
                    {cabeceraFacturas}

                    {lineasPedidos}
                </Container>
                <br />
            </Fragment>
        </MuiThemeProvider>
    );
}

export default withTranslation()(MisFacturas)