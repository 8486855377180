import queryString from 'query-string'

import config from './../config/config.js'



const rutaApi = config.rutaApisCliente + '/productos'




const listViejo = (params) => {
  const query = queryString.stringify(params)
  return fetch(rutaApi + '/list?' + query, {
    method: 'GET',
  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))
}




const list = (params) => {
  return fetch(rutaApi + '/list', {
    method: 'GET',
  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))
}







/////////////////////
///
// SOBRAN en CLIENTE
/////////////////////

const listSearchSOBRA = (params) => {
  const query = queryString.stringify(params)
  return fetch(config.miServidor + '/api/productsSearch?' + query, {
    method: 'GET',
  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))
}



const createSOBRA = (params, credentials, product) => {
  return fetch(config.miServidor + '/api/products/by/' + params.shopId, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    },
    body: product
  })
    .then((response) => {
      return response.json()
    }).catch((err) => console.log(err))
}

const readSOBRA = (params) => {
  return fetch(config.miServidor + '/api/products/' + params.productId, {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}

const updateSOBRA = (params, credentials, product) => {
  return fetch(config.miServidor + '/api/product/' + params.shopId + '/' + params.productId, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    },
    body: product
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}

const removeSOBRA = (params, credentials) => {
  return fetch(config.miServidor + '/api/product/' + params.shopId + '/' + params.productId, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    }
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}

const listByShopSOBRA = (params) => {
  return fetch(config.miServidor + '/api/products/by/' + params.shopId, {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const listRelatedSOBRA = (params) => {
  return fetch(config.miServidor + '/api/products/related/' + params.productId, {
    method: 'GET',
  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))
}

const listCategoriesSOBRA = () => {
  return fetch(config.miServidor + '/api/products/categories', {
    method: 'GET',
  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))
}





export {
  createSOBRA,
  readSOBRA,
  updateSOBRA,
  removeSOBRA,
  listByShopSOBRA,
  listRelatedSOBRA,
  listCategoriesSOBRA,
  listSearchSOBRA,
  list,
  listViejo
}
