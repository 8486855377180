import React, { Fragment } from "react";
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";

import { withTranslation } from 'react-i18next'


import { useStore } from 'react-hookstore';
import Typography from '@material-ui/core/Typography';
//import LogoCabecera from '../assets/images/logos/logoSarekoHorizontalMarron.png'
import LogoCabecera from '../assets/images/logos/merkaturatzeSarea.png'


const useStyles = makeStyles(theme => ({
    '@media screen and (orientation:portrait)': {
        root: {
            flexGrow: 1,
        },
        container: {
            maxHeight: '5vh'
        },
        imgLogoCabecera: {
            whiteSpace: "nowrap",
            maxHeight: "7.5vh",
            marginTop: '1px',
            marginLeft: '12px',
            itemAlign: "left"
        },
        user: {
            fontSize: '14px',
            fontWeight: '500',
            marginRight: '10px',
        },
     },
    '@media screen and (orientation:landscape)': {
        root: {
            flexGrow: 1,
        },
        container: {
            maxHeight: '5.5vh'
        },
        imgLogoCabecera: {
            maxHeight: "8vh",
            marginTop: '10px',
            marginLeft: '20px',
            itemAlign: "left"
        },
        user: {
            fontSize: '18px',
            fontWeight: '600',
            marginRight: '60px'
            
        }
    }
}));


const Cabecera = ({t}) => {

    const [valuesGlobales, ] = useStore('DatosGlobales');

    const classes = useStyles();

    return (
        <Fragment>
             <Grid container justifyContent="space-between" alignItems='baseline' className={classes.container}>
            
                <Grid item >
                    <Link to="/">
                        <img src={LogoCabecera} alt={'Sareko'} title={'Sareko'} className={classes.imgLogoCabecera} />
                    </Link>
                </Grid>

                <Grid item className={classes.user}>
                    <Typography style={{ color: '#026747', fontSize: 14, fontWeight: 600}} display="inline" >
                        {valuesGlobales.nombreUsuario}
                    </Typography>
                </Grid>
            </Grid>
        </Fragment>
    );
}



export default withTranslation()(Cabecera)
