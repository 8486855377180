import React, {useState, Fragment } from 'react';
//import { useStore} from 'react-hookstore';
import { Redirect } from "react-router-dom"
import { MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
//import { Link } from "react-router-dom"
import ThemeGeroa from '../ThemeGeroa.js'


const useStyles = makeStyles(theme => ({
      boton: {
        border: '1px solid #487e4c',
        backgroundColor: '#FAFAFA',
        borderRadius: '8px',
        color: 'FFF',
        fontWeight: '600',
        width: '130px',
        minWidth: '130px',
        height: '60px',
        outline: 'none',
        textAlign: 'center',
        display: 'inline-block',
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        transition: 'all 0.5s',
        cursor: 'pointer',
        margin: '5px',
        "&:hover": {
            borderRadius: '8px',
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        "&:active": {
            borderRadius: '8px',
            transform: 'translateY(8px)'
        }
    },

}));


export default function BotonDecorado({ ...props }) {
    const classes = useStyles();

    //const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');
    const [parametros, setParametros] = useState( {
        // Parametros que no vienen del fuente que llama al boton
        seSalta: false
    })

    const ancho = props.texto.length <= 20 ? "34%" : "24%"

    async function onClickBotonValidacion(event)  {
        event.preventDefault()
        let retorno = await props.validacion(event)
        // No hace falta. Cuando se llama a Botonera ya tenemos 'intentoMercado'. //
        //setValuesGlobales({ ...valuesGlobales, intentoMercado: props.mercadoNumero});
        setParametros({ ...parametros, seSalta: retorno})
    }

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Button className={classes.boton}
                //component={Link} to={props.salto}
                disabled={props.desactivado}
                onClick={onClickBotonValidacion}
            >
                {props.imagen ?
                    <Fragment>
                        <img src={props.imagen} style={{width: ancho, marginTop: 0 }} alt={"imagen"} />
                        <Typography style={{ color: '#656565', fontSize: 9, fontWeight: 500 }}>
                            {props.texto}
                        </Typography>
                    </Fragment>
                :
                    <Typography style={{ color: '#656565', fontSize: 15, fontWeight: 500 }}>
                        {props.texto}
                    </Typography>
                }
            </Button>
            {(() => {
                if (parametros.seSalta) {
                    return <Redirect to={props.salto} />
                }
            })()}
        </MuiThemeProvider>
    )
}