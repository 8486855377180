import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ThemeGeroa from '../ThemeGeroa.js'
import Calendario from '@material-ui/icons/DateRange'
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton'




export default function BotonCalendario({ ...props }) {


    // Parámetros recibidos: Eemplo de llamada:
    //        <BotonCarrito
    //           salto='/Inicio'                    // Obligatorio
    //           numero='7'                         // Obligatorio
    //           desactivado={true}                 // Opcional
    //        >
    //        </BotonCarrito>
    //
    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <IconButton aria-label="Carrito de la Compra" color="inherit"
                onClick={props.onClick}
                disabled={props.desactivado || props.salto === ''}
                style={props.style}
            >
                <Badge badgeContent={props.numero} color="secondary"  overlap="circular">
                    <Calendario fontSize="large"/>
                </Badge>
            </IconButton>
        </MuiThemeProvider>
    )
}