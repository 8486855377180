import React, { useState, useEffect } from "react";
import { useStore} from 'react-hookstore';
import { makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles'

import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'

import ThemeGeroa from './../ThemeGeroa.js'
import { FechaLargaEsp } from './../../config/funcionesPropias.js'

// Si hiciera falta traducir: Activar withTrans... y useStore
//import { withTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: '30px',
        padding: '4px 4px 4px 4px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 12,
        height: 12,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#487e4c',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 12,
            height: 12,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
    control: {
        //marginTop: '30px',
        marginTop: '0px',
    },
}));


function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

export default function RadioEntregasMercado({lista, numero}) {
    const classes = useStyles();
    const [valuesGlobales, /*setValuesGlobales*/] = useStore('DatosGlobales');  
    const [value, setValue] = useState("0");

    useEffect(() => {
        let index = lista.findIndex( ele => ele.seleccion )
        setValue(String(index))
    }, [lista])

    const change = event => {
        event.preventDefault()
        // quitar la selección de todos. //
        lista.map( ele => ele.seleccion = false )
        // Puntero al elemento en la lista
        let index = Number(event.target.value)
        lista[index].seleccion = true
        // Actualiza gancho. //
        setValue(event.target.value)
    }

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <CssBaseline />
            <FormControl component="fieldset" className={classes.control}>
                <RadioGroup value={value}
                            aria-label="fechaEntrega"
                            name="fechaEntrega"
                            onChange={change}
                >
                    {lista.map((ele, i) =>(
                        i < numero &&
                            (<FormControlLabel
                                key={"Fec"+ i}
                                value={String(i)}
                                control={<StyledRadio />}
                                label={FechaLargaEsp(ele.fecha, valuesGlobales.idioma)}
                            />)
                    ))}
                </RadioGroup>
            </FormControl>
        </MuiThemeProvider >
    );
}

//export default withTranslation()(RadioEntregas)