import React, { Fragment, useState, useEffect } from "react";
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import { useStore} from 'react-hookstore';

import config from './../config/config.js'
import { withTranslation } from 'react-i18next'

import BotonDecorado from './../components/botones/BotonDecorado.js'
import BotonDecoradoCarro from './../components/botones/BotonDecoradoCarro.js'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '10px',
        marginBottom: '20px',
    },
    fila: {
        display: "flex",
        //justifyContent: 'flex-end',
        //justify: "flex-start",
//        [theme.breakpoints.up("xs")]: {flexFlow: "row"},
//        [theme.breakpoints.down("xs")]: {flexFlow: "column"},
        //border: "2px solid red",
    },
    filaIzda: {
        display: "flex",
        //justifyContent: 'flex-end',
        [theme.breakpoints.up("xs")]: {flexFlow: "row"},
        [theme.breakpoints.down("xs")]: {flexFlow: "column"},
        //border: "2px solid red",
    },
    filaDcha: {
        display: "flex",
        justifyContent: 'flex-end',
        [theme.breakpoints.up("xs")]: {flexFlow: "row"},
        [theme.breakpoints.down("xs")]: {flexFlow: "column"},
        //border: "2px solid blue",
    },
    columna: {
        display: "flex",
        flexFlow: "column",
        //border: "2px solid yellow",
    },
}));

const Botonera = ({t}) => {
    const classes = useStyles();
    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');

    // guardar en una variable de estado para que lo actualice
    const [mercadoActual, setMercadoActual] = useState('')

    useEffect(() => {
        let montado = true
        /*
        // Buscar el mercado en el array.
        let mer = valuesGlobales.mercados
        let fin = mer.length
        let mercado = valuesGlobales.intentoMercado
        let act = ''
        for (let i=0; i<fin; ++i) {
            if(mer[i].name === mercado){
                act = mer[i].description
                break
            }
        }
        */
        // Nombre del Mercado. //
        let mercado = valuesGlobales.mercados.find( mer => mer.name === valuesGlobales.intentoMercado )
        let nombre = mercado?.description || ""
        if (montado) {
            // Gancho con el Mercado
            setMercadoActual(nombre)
        }

        // Función para evitar el error: Can't perform a React state update on an unmounted component, //
        return function cleanup() {
            montado = false
        }
    },
    // Incluir estos valores en el array de dependencias,
    // para que no dé error el compilador.
    // ¡¡ Importante !!
    // Estos parámetros hacen que se vuelva a llamar
    // a useEffect cuando cambien sus valores, por tanto,
    // si mercadoActual se actualiza en el useEffect entra en bucle.
    //[mercadoActual, valuesGlobales])
    //[valuesGlobales.emailUsuario])
    [])

    function handleClick() {
        // Opción seleccionada. //
        let opcion = this.opcion

        // Actualizar con setValuesGlobales hace que casque o
        // no lo actualiza bien porque fuerza un Return.
        //setValuesGlobales({...valuesGlobales, opcionMisProductos: this.opcion})
        //valuesGlobales.opcionMisProductos = this.opcion

        // Si es la opción Ya Conocidos o Ultimo Pedido y no está identificado
        if ((opcion === "3" || opcion === "4") && valuesGlobales.emailUsuario === '') {
            // Poner la página origen. //
            //valuesGlobales.paginaOrigen = '/MisProductos'
            setValuesGlobales({...valuesGlobales,
                                paginaOrigen: '/MisProductos',
                                opcionMisProductos: opcion})
        }
        else {
            setValuesGlobales({...valuesGlobales, opcionMisProductos: opcion})
        }
        return true
    }

    return (
        <Fragment>
            <CssBaseline />
            <Grid className={classes.root}>
                <Grid item className={classes.fila} xs={12} /*justify="center"*/ >
                    <Grid item className={classes.filaIzda} xs={6}>
                        <Grid>
                            <img alt={mercadoActual} width="130px" height="130px"
                                src={config.misImagenes +'/mercados/mer'+ valuesGlobales.intentoMercado +'.jpg'}
                            />
                        </Grid>
                        <Grid container className={classes.columna}>
                            <Grid item>
                                <BotonDecorado
                                    opcion="1"
                                    salto='/MisProductos'
                                    //titulo={t("MisProductos.Productos")}
                                    //texto={mercadoActual} // Gancho para actualizar el valor
                                    //imagen={config.misImagenes +'/mercados/mer'+ valuesGlobales.intentoMercado +'.jpg'}
                                    texto={t("MisProductos.Productos")}
                                    validacion={handleClick}
                                />
                            </Grid>
                            <Grid item>
                                <BotonDecorado
                                    opcion="2"
                                    salto="/MisProductos"
                                    texto={t("MisProductos.Novedades")}
                                    validacion={handleClick}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.filaDcha} xs={6} /*align={"right"}*/>
                        <Grid item className={classes.columna} xs={6} align={"right"}>
                            <Grid item>
                                <BotonDecorado
                                    opcion="3"
                                    salto={valuesGlobales.emailUsuario !== "" ? "/MisProductos" : "/Identificarse"}
                                    texto={t("MisProductos.YaConocidos")}
                                    validacion={handleClick}
                                />
                            </Grid>
                            <Grid item>
                                <BotonDecorado
                                    opcion="4"
                                    salto={valuesGlobales.emailUsuario !== "" ? "/MisProductos" : "/Identificarse"}
                                    texto={t("MisProductos.UltimoPedido")}
                                    validacion={handleClick}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <BotonDecoradoCarro
                                opcion="5"
                                numero={valuesGlobales.numeroCarrito}
                                productos={valuesGlobales.productos}
                                carrito={valuesGlobales.carrito}
                                salto='/CarroCompra'
                                validacion={handleClick}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default withTranslation()(Botonera)
