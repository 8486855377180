import config from './../config/config.js'

const rutaApi = config.rutaApisCliente+'/pedidos'


const listByUserCli = (params, credentials) => {
  return fetch(rutaApi+'/listByUserCli/'+params.userId, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    }
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const createVariosPedidos = (params, credentials, orders) => {
  return fetch(rutaApi+'/createVariosPedidos', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + credentials.t
      },
      body: JSON.stringify({orders: orders})
    })
    .then((response) => {
      return response.json()
    }).catch((err) => console.log(err))
}


const updateEstados = (params, credentials, order) => {
  return fetch(rutaApi+'/updateEstados/'+params.orderId, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    },
    body: JSON.stringify(order)
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const cancelaPedido = (params, credentials) => {
  return fetch(rutaApi+'/cancelaPedido/'+params.orderId+'/'+params.restaExistencias+'/'+params.fechaPedido, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    }
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const listByUser = (params, credentials) => {
  let query = ""
  if (params.dF !== undefined) {
     query = "?dF="+params.dF+"&hF="+params.hF
  }
  return fetch(rutaApi+'/listByUser/user/'+params.userId+query, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    }
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const listByUserFacturado = (params, credentials) => {
  return fetch(rutaApi+'/userFacturado/'+params.userEmail, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    }
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const listUltimoPedidoUsMer = (params, credentials) => {
  let query = "?mercado="+params.mercado
  return fetch(rutaApi+'/listUltimoPedidoUsMer/'+params.userId+query, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    }
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const listPedFacCli = (params, credentials) => {
  return fetch(rutaApi+'/listPedFacCli/'+params.cliente, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    }
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


export {
  createVariosPedidos,
  updateEstados,
  cancelaPedido,
  listByUser,
  listByUserCli,
  listByUserFacturado,
  listUltimoPedidoUsMer,
  listPedFacCli
}