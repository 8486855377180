import React from 'react';
import { useStore } from 'react-hookstore';
import { withTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
//import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
//import Typography from '@material-ui/core/Typography';

import BotonHeaderSimple from '../components/botones/BotonHeaderSimple.js';
import BotonPdfAyuda from '../components/botones/BotonPdffAyuda.js';

import { IrAProductosMercado } from '../config/funcionesParticulares'
import config from './../config/config.js'



const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    botonMenu: {
        marginTop: '0px',
        marginRight: '18px',
        marginLeft: '40px',
        color: '#FFF'
    },
    botonMenuInicio: {
        marginTop: '0px',
        marginRight: '8px',
        marginLeft: '0.9rem',
        color: '#FFF'
    },
}));


const MenuBar = ({ t }) => {
    const classes = useStyles();
    const [valuesGlobales,] = useStore('DatosGlobales');
    //console.log('aaaa = ', config.rutaCarpetaFicherosparaWebs)
    return (
        <Container className={classes.root}>
            <Grid item className={classes.botonMenuInicio}>
                <BotonHeaderSimple
                    salto={"/"}
                    texto={t('Cabecera.Mercados')}
                />
            </Grid>
            <Grid item className={classes.botonMenuInicio}>
                <BotonHeaderSimple
                    salto={IrAProductosMercado(valuesGlobales)}
                    texto={t('Cabecera.Pedidos')}
                />
            </Grid>
            <Grid item className={classes.botonMenu}>
                <BotonHeaderSimple
                    salto={valuesGlobales.emailUsuario !== "" ? "/MisPedidos" : "/Identificarse"}
                    texto={t('Cabecera.Gestion')}
                />
            </Grid>
            <Grid item className={classes.botonMenu}>
                <BotonHeaderSimple
                    salto={valuesGlobales.emailUsuario !== "" ? "/MisFacturas" : "/Identificarse"}
                    texto={t('Cabecera.Facturas')}
                />
            </Grid>
            <BotonPdfAyuda
                fichero={config.rutaCarpetaFicherosparaWebs + '/Ayuda.pdf'}
            />
        </Container>
    );
}

export default withTranslation()(MenuBar)