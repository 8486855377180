import React from "react";
import { useStore} from 'react-hookstore';
import { withTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';

import ThemeGeroa from '../../components/ThemeGeroa.js'
import BotonValidacion from '../../components/botones/BotonValidacion.js';

const useStyles = makeStyles(theme => ({
     registro: {
        marginTop: '20px',
        minHeight: '65vh'
    },
    boton: {
        color: '#FFF',
        backgroundColor: '#1b5e20',
        textTransform: "none",
        marginRight: '10px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "#4caf50",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
    },
}));


const PagoCancelado = ({ t }) => {
    const classes = useStyles();
    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales')

    const handleAceptar = () => {
        setValuesGlobales({...valuesGlobales, origenRedsys: false})
        return true
    }

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Container maxWidth="md" className={classes.registro}>
                <Typography style={{ fontSize: '16px', color: '#656565', fontWeight: 600, marginTop: 20, marginLeft: 0 }} display="inline">
                    {t("Carro.ErrorTarjeta")}
                </Typography>
                <br/>
                <br/>
                <BotonValidacion
                        texto={t("Carro.Volver")}
                        validacion = {handleAceptar}
                        salto ={"/"}
                />
            </Container>
        </MuiThemeProvider >
    )
}

export default withTranslation()(PagoCancelado)