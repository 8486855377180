import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import ThemeGeroa from '../ThemeGeroa.js'


const useStyles = makeStyles(theme => ({
    buttonMenu: {
        textTransform: "none",
        fontSize: "16px",
        padding: '0 !important',
        fontWeight: "400",
        color: "#FFF",
        backgroundColor: "transparent",
        [ThemeGeroa.breakpoints.down('md')]: {
            fontSize: '13px'
        },
        maxHeight: '30px',
        minWidth: '30px',
        minHeight: '30px',
        "&:hover,&:focus": {
            backgroundColor: "transparent",
            cursor: "hand"
        }
    },

}));

export default function BotonDrawer({ ...props }) {
    const classes = useStyles();
    return (
        <MuiThemeProvider theme={ThemeGeroa} >
            <Switch>
                <Route
                    exact
                    path={props.to}
                    render={() => (
                        <Button className={classes.buttonMenu} 
                                component={Link} {...props} >
                            {props.texto}
                        </Button>
                    )}
                />
                <Route
                    path="/"
                    render={() => (
                        <Button className={classes.buttonMenu} 
                                component={Link} {...props} >
                            {props.texto}
                        </Button>
                    )}
                />
            </Switch>
        </MuiThemeProvider>
    );
}