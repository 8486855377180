import React, {useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import ThemeGeroa from '../ThemeGeroa.js'
import { Redirect } from "react-router-dom"


const useStyles = makeStyles(theme => ({
    boton: {
        marginLeft: 30,
        marginTop : 25,
        textTransform: "none",
        fontSize: "16px",
        [ThemeGeroa.breakpoints.down('md')]: { fontSize: '13px' },
        padding: "0px 20px 0px 20px !important",
        [ThemeGeroa.breakpoints.down('md')]: { padding: '0px 5px 0px 5px !important' },
        fontWeight: "400",
        color: "#FFF",
        backgroundColor: ThemeGeroa.palette.primary.main,
        maxHeight: '30px',
        minWidth: '90px',
        minHeight: '70px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: ThemeGeroa.palette.secondary.light,
            cursor: "hand",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        "&:disabled": {
            backgroundColor: ThemeGeroa.palette.secondary.dark,
        },
    },

}));




export default function BotonMisMercados({ ...props }) {
    const classes = useStyles();
  
    const [parametros, setParametros] = useState( {
        // Parametros que no vienen del fuente que llama al boton
        seSalta: false
    })


    
    async function onClickBotonValidacion(event)  {
        event.preventDefault()
        let retorno = await props.validacion(event)
        setParametros({ ...parametros, seSalta: retorno})
    }


    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Button className={classes.boton}
                onClick={onClickBotonValidacion}
                disabled={props.desactivado} >
                {props.texto}
            </Button>
            {(() => {
                if (parametros.seSalta) {
                    return <Redirect to={props.salto} />
                }
            })()}

        </MuiThemeProvider>
    )
}