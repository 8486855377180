import React, { useState, useEffect } from "react";
import { useStore } from 'react-hookstore';
import { withTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';

//import {decodeMerchantParameters} from './RedsysPropia'

import ThemeGeroa from './../../components/ThemeGeroa.js'
import BotonValidacion from './../../components/botones/BotonValidacion.js';

import auth from './../../apis/auth-helper.js'
import { enviaEmail } from './../../apis/api-email.js'
import { createVariosPedidos } from './../../apis/api-pedido.js'

const useStyles = makeStyles(theme => ({
    registro: {
        marginTop: '20px',
        minHeight: '65vh'
    },
    boton: {
        color: '#FFF',
        backgroundColor: '#1b5e20',
        textTransform: "none",
        marginRight: '10px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "#4caf50",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
    },
}));



//const FinalizarPago = ({ t, ...props }) => {
const FinalizarPago = ({ t }) => {
    const classes = useStyles();

    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales')

    const [textoFinal, setTextoFinal] = useState()

    /* Por ahora no se utiliza este gancho. */
    /*
    const [retRedsys, setRetRedsys] = useState({
        Ds_SignatureVersion: "",
        Ds_MerchantParameters: "",
        Ds_Signature: "",
        ParamsDevueltos: ""
    })
    */

    useEffect(() => {
        const jwt = auth.isAuthenticated()
        let listaPedidos
        let pedidoEmail
        //let hayQueRegistrar = true
        //
        if (!valuesGlobales.origenRedsys) {
            // No viene de Redsys (no hay pago con tarjeta)
            listaPedidos = valuesGlobales.pedidosAGuardar
            pedidoEmail = valuesGlobales.pedidosAEnviar
        } else {
            // Viene de Redsys (pago con tarjeta)
            listaPedidos = JSON.parse(localStorage.getItem('pedidosAGuardar'))
            pedidoEmail = JSON.parse(localStorage.getItem('pedidosAEnviar'))
            // Poner Forma de Pago: Tarjeta.
            listaPedidos.map((pedido, i) => {
                if (i === 0) {
                    // Tarjeta
                    pedido.fPago = 'J'
                } else {
                    if (jwt.user.transferencia) {
                        // Transferencia
                        pedido.fPago = 'T'
                    } else {
                        // Recibo
                        pedido.fPago = 'R'
                    }
                }
                return null
            })
            pedidoEmail.fPago = 'Tarjeta'
            /*
            const retorno = props.location.search.substr(1)
            if(retorno !== undefined && retorno !== ""){
                var arrayDeCadenas = retorno.split("&")
                //let r1_0 = arrayDeCadenas[0].substr(0,arrayDeCadenas[0].indexOf("="))
                //let r1_1 = arrayDeCadenas[0].substr(arrayDeCadenas[0].indexOf("=")+1)
                //let r2_0 = arrayDeCadenas[1].substr(0,arrayDeCadenas[1].indexOf("="))
                let r2_1 = arrayDeCadenas[1].substr(arrayDeCadenas[1].indexOf("=")+1)
                //let r3_0 = arrayDeCadenas[2].substr(0,arrayDeCadenas[2].indexOf("="))
                //let r3_1 = arrayDeCadenas[2].substr(arrayDeCadenas[2].indexOf("=")+1)

                // Quitar de la barra url los parámetros NO FUNCIONA
                //setInterval ("window.status = ''",10)
                //history.pushState(null, '', 'finalizarPago');
                //BrowserHistory.replace("/finalizarPago")

                //
                //
                let vueltaDecodificada = decodeMerchantParameters(r2_1)
                //
                // Este gancho y estos valores no se usan por ahora. //
                //setRetRedsys({ Ds_SignatureVersion: r1_1, Ds_MerchantParameters: r2_1, Ds_Signature: r3_1, ParamsDevueltos: vueltaDecodificada})
                //
                if(vueltaDecodificada.Ds_Response > "0099"){
                    // Error
                    hayQueRegistrar = false
                }
                else{
                    // No hay Error
                    hayQueRegistrar = true
                }
            }
            */
        }
        // 
        //if(hayQueRegistrar){
        // Permitir que una cuenta de sareko NO CREE PEDIDOS en la base de datos, ni descuente existencias
        if (valuesGlobales.emailUsuario === "aaaa@gmail.com") {
            // Solo se hace el envío por mail, pero no se llega a Registrar NADA
            pedidoEmail._id = listaPedidos.length + ' Pedidos diferentes'
            pedidoEmail.fecha = new Date()
            //pedidoEmail.repeticiones = listaPedidos[0].repeticiones

            vaciarCarro()
            envioCorreos(pedidoEmail, jwt.token)
        }
        else {
            crearPedidoBajarExisEnviarMail(pedidoEmail, jwt, listaPedidos)
        }
        // Visualiza Resultado ok
        //if (esOK) {
        //    setTextoFinal(t("Carro.Confirmacion"))
        //} else {
        //    setTextoFinal(t("Carro.ErrorPedido"))
        //}
        //} else {
        //    setTextoFinal(t("Carro.ErrorTarjeta"))
        //}

        // El array vacío indica que UseEffect sólo se carga una vez. //
    }, [])


    function vaciarCarro() {
        localStorage.removeItem('carrito')
        localStorage.removeItem('mercadoActual')
        setValuesGlobales({ ...valuesGlobales, mercadoActual: '', carrito: {}, numeroCarrito: 0 })
    }


    async function crearPedidoBajarExisEnviarMail(pedidoEmail, jwt, listaPedidos) {
        // Transformar el texto de la forma de pago
        listaPedidos.forEach((p) => {
            // Traducir Forma de Pago, si viene en formato antiguo //
            let fpg = p.fPago
            if (fpg === 'Recibo') {
                fpg = 'R'
            } else if (fpg === 'Tarjeta') {
                fpg = 'J'
            } else if (fpg === 'Transferencia') {
                fpg = 'T'
            } else if (fpg === 'Tpv') {
                fpg = 'V'
            } else if (fpg === 'Productor') {
                fpg = 'P'
            }
            p.fPago = fpg
        })
        // Crear los Pedidos y si todo correcto, enviar mail. //
        let data = await createVariosPedidos({ userId: jwt.user._id }, { t: jwt.token }, listaPedidos)
        if (data.error) {
            console.log("Error:", data)
            setTextoFinal(t("Carro.ErrorPedido"))
        }
        else {
            // Enviar por mail los Números de Pedido Generados. //
            pedidoEmail._id = ''
            data.forEach((pedido) => {
                //console.log("Pedido: ", pedido)
                if (pedido.restaExistencias) {
                    if (pedidoEmail._id !== '') {
                        pedidoEmail._id = pedidoEmail._id + ', '
                    }
                    pedidoEmail._id = pedidoEmail._id + pedido._id
                }
            })

            //pedidoEmail.repeticiones = listaPedidos[0].repeticiones
            vaciarCarro()
            envioCorreos(pedidoEmail, jwt.token)
        }
    }



    async function envioCorreos(pedido, token) {
        if (pedido.mercado !== "026") {
            let mailsEnviados = await enviaEmail(valuesGlobales.emailUsuario, pedido, "1", t("Email.TextoSubjectMail"), { t: token })
            if (mailsEnviados.error) {
                console.log("Error:", mailsEnviados)
                //setTextoFinal("Error al enviar eMail de confirmacion")
                setTextoFinal(t("Carro.ErrorEmail"))
            }
            else {
                console.log('Envio ok = ', mailsEnviados)
                setTextoFinal(t("Carro.Confirmacion"))
            }
        }
    }


    const handleAceptar = () => {
        setValuesGlobales({ ...valuesGlobales, origenRedsys: false })
        return true
    }


    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Container maxWidth="md" className={classes.registro}>
                <Typography style={{ fontSize: '16px', color: '#656565', fontWeight: 600, marginTop: 20, marginLeft: 0 }} display="inline">
                    {textoFinal}
                </Typography>
                <br />
                <br />
                <BotonValidacion
                    texto={t("Carro.Volver")}
                    validacion={handleAceptar}
                    salto={"/"}
                />
            </Container>
        </MuiThemeProvider >
    )
}

export default withTranslation()(FinalizarPago)