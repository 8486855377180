import React, { Fragment } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import MenuBar from './menuBar.js'
import { useStore } from 'react-hookstore';
import DrawerGeroa from './../components/menus/DrawerGeroa.js'
import BotonHeaderSelectIdiomas from '../components/botones/BotonHeaderSelectIdiomas.js';
import BotonHeaderLogin from '../components/botones/BotonHeaderLogin.js'
import BotonCarrito from '../components/botones/BotonCarrito.js'
import BotonNotificaciones from '../components/botones/BotonNotificaciones.js'

import Config from './../config/config.js'
import { IrAProductosMercado, IrAlCarro } from '../config/funcionesParticulares'

import { withTranslation } from 'react-i18next'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: -drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    menuMain: {
        zIndex: "4000",
    },
    appBar: {
        marginTop: '40px',
        //backgroundColor: '#1b5e20',
        marginRight: -drawerWidth,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    listButton: {
        color: '#757575'
    },
    botonDrawer: {
        marginLeft: '10px',
        color: '#757575'
    }
}));


const Header = ({ t }) => {
    const classes = useStyles();

    const [valuesGlobales,] = useStore('DatosGlobales');
    const [open, setOpen] = useStore('drawerStore');

    let miLista = []
    miLista[0] = { "texto": "Es", "habilitado": true }
    miLista[1] = { "texto": "Eus", "habilitado": true }

    let miListaLogin = []
    miListaLogin[0] = { texto: t("MenuIdentificarse.Identificarse"), habilitado: (valuesGlobales.nombreUsuario === '' ? true : false), salto: '/Identificarse' }
    miListaLogin[1] = { texto: t("MenuIdentificarse.Desconectarse"), habilitado: (valuesGlobales.nombreUsuario !== '' ? true : false), salto: '/' }
    miListaLogin[2] = { texto: t("MenuIdentificarse.GestionUsuario"), habilitado: true, salto: '/Usuarios' }


    let miListaDrawerGeroa = []
    miListaDrawerGeroa[0] = { texto: t("MenuDrawer.MERCADOS"), habilitado: true, salto: '/' }
    miListaDrawerGeroa[1] = { texto: t("MenuDrawer.PRODUCTOS"), habilitado: true, salto: IrAProductosMercado(valuesGlobales) }
    miListaDrawerGeroa[2] = { texto: t("MenuDrawer.PEDIDOS"), habilitado: true, salto: (valuesGlobales.nombreUsuario !== '' ? '/MisPedidos' : '/Identificarse') }
    miListaDrawerGeroa[3] = { texto: t("MenuDrawer.FACTURAS"), habilitado: true, salto: (valuesGlobales.nombreUsuario !== '' ? '/MisFacturas' : '/Identificarse') }



    function handleDrawerOpen() {
        setOpen(true);
    }

    return (
        <Fragment>
            <Grid item xs={12} className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="sticky"
                    color="primary"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <Typography variant="h6" noWrap className={classes.title}></Typography>
                        <Hidden only={'xs'}>
                            <Grid item >
                                <MenuBar className={classes.menuMain} />
                            </Grid>
                        </Hidden>
                        <Typography variant="h6" noWrap className={classes.title}> </Typography>
                        <Grid item className={classes.botonMenu}>
                            <BotonHeaderSelectIdiomas
                                opciones={miLista}
                                opcionSeleccionada={valuesGlobales.idioma}
                            />
                        </Grid>
                        <Grid item className={classes.botonMenu}>
                            <BotonHeaderLogin
                                opciones={miListaLogin}
                                textoTitulo={t('CajaDesconectar.titulo')}
                                textoCaja={t('CajaDesconectar.texto')}
                                botonCancelarCaja={t('CajaDesconectar.botonCancelar')}
                                botonAceptarCaja={t('CajaDesconectar.botonAceptar')}
                            />
                        </Grid>
                        <Grid>
                            <BotonCarrito
                                salto={IrAlCarro(valuesGlobales)}
                                numero={valuesGlobales.numeroCarrito}
                            />
                        </Grid>
                        {Config.notificaciones ?
                            <Grid>
                                <BotonNotificaciones
                                    notificaciones={valuesGlobales.notificaciones}
                                />
                            </Grid>
                            :
                            <Fragment />
                        }
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            className={clsx(open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DrawerGeroa
                    opciones={miListaDrawerGeroa}
                />
            </Grid>
        </Fragment>
    );
};

export default withTranslation()(Header)