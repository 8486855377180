import React, { Fragment } from "react";
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Boton from '../components/botones/Boton.js';
import Box from '@material-ui/core/Box'
import Sareko from '../assets/images/logos/merkaturatzeSarea.png';


const useStyles = makeStyles(theme => ({
    '@media screen and (orientation:landscape)': {
        registro: {
            maxWidth: '700px',
            marginTop: '20px',
            minHeight: '63vh',
            backgroundRepeat: 'no-repeat'
        },
    },
    '@media screen and (orientation:portrait)': {
        registro: {
            maxWidth: '700px',
            marginTop: '20px',
            minHeight: '62vh',
            backgroundRepeat: 'no-repeat'
        },
    },
    boton: {
        color: '#FFF',
        backgroundColor: '#1b5e20',
        textTransform: "none",
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "#4caf50",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
    },
}));



export default function Main(a) {
    const classes = useStyles();

    return (
        <Fragment>
            <Container maxWidth="md" style={{ backgroundImage: `url(${Sareko})`, backgroundPosition: "center", backgroundSize: "80%" }} className={classes.registro} >
                <Typography align="left" style={{ fontSize: '32px', color: '#00233D', fontWeight: 600, marginTop: 20, marginBottom: 18 }}>
                    Página no encontrada. Error 404.
                    </Typography>
                <Typography style={{ fontSize: '16px', color: '#00233D', fontWeight: 400, marginTop: 20, marginBottom: 18 }}>
                    La página que está buscando podría haber sido removida, haber cambiado su nombre, o estar temporalmente fuera de servicio.
                    </Typography>
                <Typography style={{ fontSize: '24px', color: '#00233D', fontWeight: 600, marginTop: 24, marginBottom: 24 }}>
                    ¿Qué puedo hacer?
                    </Typography>
                <Typography style={{ fontSize: '16px', color: '#00233D', fontWeight: 400, marginTop: 24, marginBottom: 24 }}>
                    <ul>
                        <li>Compruebe que ha tecleado correctamente la URL.</li>
                        <li>Utilice el botón inferior para volver a Inicio.</li>
                        <li>Use el menú superior para elegir otra opción.</li>
                    </ul>
                </Typography>

                <Box display="flex" justifyContent="flex-end" >
                    <Boton
                        texto='Volver'

                        salto='/'
                    >
                    </Boton>
                </Box>
            </Container>
        </Fragment >
    );
}



