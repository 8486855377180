import { createTheme, createStyles } from "@material-ui/core";
import { green, amber, red, orange } from '@material-ui/core/colors'
import { responsiveFontSizes } from '@material-ui/core/styles';

const ThemeGeroa = createTheme({

  palette: {
    primary: {
      main: "#026747",
      light: amber[300],
      dark: green[900],
      contrastText: '#FFF',
    },
    secondary: {
      //main: '#846b31',
      main: "#95C11F",
      light: green[900],
      contrastText: '#fff',
    },
    rojo: {
      main: red[700],
      contrastText: '#fff',
    },
    naranja: {
      main: orange[700],
      contrastText: '#fff',
    },
    negro: {
      main: '#000'
    },
    gris: {
      main: '#444444'
    },
    tercero: {
      //main: '#78002E',
      main: '#AD1457',
      contrastText: '#FFF',
    },
  },

  typography: {
    textAlign: "left",
    color: "red"
  },



  overrides: {
    MuiFormControlLabel: createStyles({
      label: {
        fontSize: "11px",

      }
    })
  }

});



export default responsiveFontSizes(ThemeGeroa)