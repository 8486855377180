import React, { useState } from "react";
import { useStore} from 'react-hookstore';
import { withTranslation/*, Translation */} from 'react-i18next'
import clsx from 'clsx';
import { makeStyles } from       '@material-ui/styles';
import CssBaseline from          '@material-ui/core/CssBaseline';
import FormControl from          '@material-ui/core/FormControl'
import FormControlLabel from     '@material-ui/core/FormControlLabel'
import Radio from                '@material-ui/core/Radio';
import RadioGroup from           '@material-ui/core/RadioGroup';
import Typography from           '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles'
import ThemeGeroa from './../ThemeGeroa.js'

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: '30px',
        padding: '4px 4px 4px 4px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 12,
        height: 12,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#487e4c',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 12,
            height: 12,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
    control: {
        //marginTop: '30px',
        marginTop: '0px',
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 70,
        minWidth: 240,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    checkboxLabel: {
        fontSize: 16,
        marginLeft: 5
    }
}));


function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}


const RadioBotonesTipoDePago = ({ t, ...props }) => {
    const classes = useStyles();

    const [valuesGlobales, /*setValuesGlobales*/] = useStore('DatosGlobales');  
    let lista = []
    let sirve
    props.fpMercado.map((fpm, i) =>{
        sirve = true
        if(fpm.tipo === "Transferencia"){
           sirve = props.usTransferencia
        }
        if(fpm.tipo === "Recibo"){
           sirve = props.usRecibo
        }
        if(fpm.tipo === "Tarjeta"){
           sirve = !props.repeticiones
        } 
        if(sirve){
            lista.push(fpm)
         }
        return null
    })

    const [valueTipoDePago, setValueTipoDePago] = useState(lista[0].tipo)

    const changeTipoDePago = event => {
        event.preventDefault()
        props.validacion(event)
        setValueTipoDePago(event.target.value);
    }

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <CssBaseline />
            <FormControl component="fieldset" className={classes.control}>
                <Typography style={{ color: '#846b31',
                                            fontSize: 18,
                                            fontWeight: 600,
                                            marginLeft: 10 }} display="inline">
                    {t("Carro.FormaDePago")}
                </Typography>
                <RadioGroup value={valueTipoDePago}
                            aria-label="tipoPago"
                            name="tipoPago"
                            onChange={changeTipoDePago}
                >
                    {lista.map((l) =>(
                        <FormControlLabel
                            key={'Fp'+ l.tipo}
                            classes={{
                                label: classes.checkboxLabel
                            }}
                            value={l.tipo}
                            control={<StyledRadio />}
                            label={valuesGlobales.idioma === "Es" ? l.textoEs : l.textoEu}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </MuiThemeProvider>
    );
}

export default withTranslation() (RadioBotonesTipoDePago)