import React, {useState} from 'react';
import { useStore} from 'react-hookstore';
import { Redirect } from "react-router-dom"
import { MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
//import { Link } from "react-router-dom"
import ThemeGeroa from '../ThemeGeroa.js'
import CartIcon from "@material-ui/icons/ShoppingCart"
import Badge from '@material-ui/core/Badge';
import {NumeroEsp, BuscaIndice, JsonAArray} from './../../config/funcionesPropias.js'
import {PrecioCliente} from './../../config/funcionesParticulares.js'

const useStyles = makeStyles(theme => ({
     boton: {
        border: '1px solid #487e4c',
        backgroundColor: '#FAFAFA',
        borderRadius: '8px',
        color: 'FFF',
        fontWeight: '600',
        width: '130px',
        minWidth: '130px',
        height: '130px', //'80px',
        outline: 'none',
        textAlign: 'center',
        display: 'inline-block',
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        transition: 'all 0.5s',
        cursor: 'pointer',
        margin: '5px',
        "&:hover": {
            borderRadius: '8px',
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        "&:active": {
            borderRadius: '8px',
            transform: 'translateY(8px)'
        }
    },
    carro: {
        fontSize: '64px',
        color: ThemeGeroa.palette.primary.main
    }
}));


export default function BotonDecoradoCarro({ ...props }) {
    const classes = useStyles();

    const [valuesGlobales, /*setValuesGlobales*/] = useStore('DatosGlobales');

    const [parametros, setParametros] = useState( {
        // Parametros que no vienen del fuente que llama al boton
        seSalta: false
    })
    
    async function onClickBotonValidacion(event)  {
        event.preventDefault()
        let retorno = await props.validacion(event)
        setParametros({ ...parametros, seSalta: retorno})
    }
    
    let importeTotalCarro = 0
    let itemsCarrito = JsonAArray(props.carrito)
    let precio
    itemsCarrito.map((linea, i) =>{
        let encontrado = BuscaIndice(props.productos, "id", linea[0])
        if(encontrado !== -1){
            precio = PrecioCliente(props.productos[encontrado].tarifaActual, props.productos[encontrado].iva, valuesGlobales.ivaUsuario)
            importeTotalCarro += precio * linea[1]
        }
        return null
    })

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Button className={classes.boton}
                //component={Link} to={props.salto}
                disabled={props.desactivado}
                onClick={onClickBotonValidacion}
            >
                <Badge badgeContent={props.numero} color="secondary" style={{ marginTop: 10}} overlap="circular">
                    <CartIcon className={classes.carro} />
                </Badge>
                <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 14, fontWeight: 500 }}>
                    {NumeroEsp(importeTotalCarro)} € 
                </Typography>
            </Button>
            {(() => {
                if (parametros.seSalta) {
                    return <Redirect to={props.salto} />
                }
            })()}
        </MuiThemeProvider>
    )
}