import React, { useState } from "react";

//import { Redirect } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
//import TextField from "@material-ui/core/TextField";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createStore, useStore } from 'react-hookstore';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';


import ThemeGeroa from '../../components/ThemeGeroa.js'

import BotonValidacion from '../../components/botones/BotonValidacion'

import { withTranslation } from 'react-i18next'

import { updatePassword } from '../../apis/api-user'


const useStyles = makeStyles(theme => ({
    registro: {
        marginTop: '0px',
        minHeight: '65vh'
    },
    mensajeHelper: {
        color: 'rgba(0, 0, 0, 0.54)',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-6px',
        minHeight: '1em',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },
    mensajeError: {
        color: '#ff3878',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-6px',
        minHeight: '1em',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },
    textField: {
        marginBottom: 12,
    },
    nounderline: {
        textDecoration: 'none',
        cursor: 'pointer',
    }
}));

createStore('NuevoPass')

const NuevoPassword = ({t, ...props}) => {
    const classes = useStyles();
    const [valuesGlobales, /*setValuesGlobales*/] = useStore('DatosGlobales');

    //const [salto, setSalto] = useState('')

    //const idUsuario = props.match.params.idUsuario

    const [values, setValues] = useStore('NuevoPass');
    const [errors, setErrors] = useState({})

    if (values.password1 === undefined) {
        values.password0 = ''
        values.password1 = ''
        values.password2 = ''
    }

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    }
    
    const handleClickShowPassword = name => event => {
        let valor = "show"+name
        setValues({ ...values, [valor]: !values[valor] });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    // Valida únicamente la sintaxis de los 3 campos (email y password)
    const validate = (values) => {
        errors.password0 = ''
        errors.password1 = ''
        errors.password2 = ''
        if (values.password0.length === 0) {
            errors.password0 = t('El código recibido en su e-mail es obligatorio')
        } else if (values.password0 !== valuesGlobales.claveCambioPassword) {
            errors.password0 = t('El código recibido en su e-mail no es correcto')
        } else if (values.password1.length === 0) {
            errors.password1 = t('El password es obligatorio')
        } else if (values.password1.length < 4) {
            errors.password1 = t('Este password parece demasiado corto')
        } else if (values.password2.length === 0) {
            errors.password2 = t('El password es obligatorio')
        } else if (values.password2.length < 4) {
            errors.password2 = t('Este password parece demasiado corto')
        } else if (values.password1 !== values.password2) {
            errors.password1 = t('Los password no coinciden')
        }
        return errors
    }

   
    async function handleSubmit(event) {
        event.preventDefault()

        // Valida que se haya rellenado el formulario
        setErrors(validate(values))
        setValues({ ...values, email: values.email});
         
        if (errors.password1 === '' && errors.password2 === ''){
            try {
                // Llama al Api para enviar un mail
                const data = await updatePassword(valuesGlobales.usuarioCambioPassword,values.password1)
                  if (data === '') {
                   throw t('El Paswword no es correcto')
                }
            }
            catch (err) {
                errors.password1 = err
            }
            // Actualizar errores.
            setErrors(errors)
            // Necesario para que pinte en pantalla si hay error. 
            setValues({ ...values, password1: values.password1});
        }
        return (errors.password0 === '' && errors.password1 === '' && errors.password2 === '') ? true : false
    }


    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Container maxWidth="md" className={classes.registro}>
                <Typography style={{ fontSize: '32px', color: '#00233D', fontWeight: 600, marginTop: 10, marginBottom: 10 }}>
                    {t('CambioPassword')}
                </Typography>
                  <Grid item xs={7}>
                    <Typography  align= "justify" style={{ fontSize: '14px', color: ThemeGeroa.palette.primary.main, fontWeight: 400, marginTop: 10, marginBottom: 18 }}>
                            {t('TextoCambioPassword')}
                    </Typography>
                    <br/>
                    <FormControl fullWidth>
                        <InputLabel required>
                            {t("TextoCodigoRecibido")}
                        </InputLabel>
                        <Input
                            className={classes.textField}
                            id="adornment-password"
                            type={values.showPassword0 ? 'text' : "Password"}
                            value={values.password0}
                            onChange={handleChange('password0')}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword('Password0')}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                        {(() => {
                            if (errors.password0 === undefined || errors.password0 === '') {
                                return <Typography className={classes.mensajeHelper}>
                                    {t('ExplicacionCodigoRecibido')}
                                    </Typography>
                            } else {
                                return <Typography className={classes.mensajeError}> {errors.password0}</Typography>
                            }
                        })()}
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel required>
                            {t("Password")}
                        </InputLabel>
                        <Input
                            className={classes.textField}
                            id="adornment-password"
                            type={values.showPassword1 ? 'text' : "Password"}
                            value={values.password1}
                            onChange={handleChange('password1')}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword('Password1')}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                        {(() => {
                            if (errors.password1 === undefined || errors.password1 === '') {
                                return <Typography className={classes.mensajeHelper}>
                                    {t('Introduzca su Password')}
                                    </Typography>
                            } else {
                                return <Typography className={classes.mensajeError}> {errors.password1}</Typography>
                            }
                        })()}
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel required>
                            {t("Repita Password")}
                        </InputLabel>
                        <Input
                            className={classes.textField}
                            id="adornment-password"
                            type={values.showPassword2 ? 'text' : "Password"}
                            value={values.password2}
                            onChange={handleChange('password2')}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword('Password2')}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                        {(() => {
                            if (errors.password2 === undefined || errors.password2 === '') {
                                return <Typography className={classes.mensajeHelper}>
                                    {t('Introduzca su Password')}
                                    </Typography>
                            } else {
                                return <Typography className={classes.mensajeError}> {errors.password2}</Typography>
                            }
                        })()}
                    </FormControl>
                    <br />
                    <br/>
                    <br/>
                    <Grid container>
                        <BotonValidacion
                            texto={t('Aceptar')}
                            salto={"/Identificarse"}
                            validacion={handleSubmit}
                        />
                    </Grid>
                </Grid>
            </Container>
         </MuiThemeProvider >
    );
}

export default withTranslation()(NuevoPassword)