import React, { Fragment, useState } from "react";
import { makeStyles, withStyles } from '@material-ui/styles';

import CssBaseline  from '@material-ui/core/CssBaseline';
import FormControl  from '@material-ui/core/FormControl'
import Select       from '@material-ui/core/Select';
import InputBase    from '@material-ui/core/InputBase';
import MenuItem     from '@material-ui/core/MenuItem';
import Divider      from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
    programa: {
        marginLeft: '10px'
    },
}));

const BootstrapInput1 = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(8),
        },
        marginLeft: "20px",
        width: '70px',
        fontWeight: 700,
        color: 'blue'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#f4f5f7',
        border: '1px solid #ced4da',
        fontSize: 14,
        padding: '4px 24px 4px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const BootstrapInput2 = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        marginLeft: "20px",
        width: '70px',
        fontWeight: 700,
        color: 'blue'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#f4f5f7',
        border: '1px solid #ced4da',
        fontSize: 14,
        width: 'auto',
        padding: '4px 24px 4px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            //boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);


export default function SelectorCantidad({ ...props }) {
    const classes = useStyles()
    const [numero, setNumero] = useState(props.valor)
    const [texto, setTexto] = useState(props.valor >= 10 ? true : false)

    const handleChange = event => {
        if (event.target.value < 10) {
            setNumero(event.target.value)
            // Ejecuta la Accion exterior
            props.accion(event.target.value)
        }
        else {
            props.accion(10)
            setTexto(true)
        }
    }

    const cantidadIntroducida = event =>{
       let entrada = event.target.value
       let salida = ""
        for(let i=0; i < entrada.length; i++){
            if(entrada.substr(i,1) >= "0" && entrada.substr(i,1) <= "9"){
               salida = salida + entrada.substr(i,1)

            }
        }
        props.accion(salida)
    }

    return (
        <Fragment>
            <CssBaseline />
            {!texto ?
                <Select
                    disabled={props.disabled}
                    value={numero}
                    onChange={handleChange}
                    input={<BootstrapInput1 
                                className={classes.programa}
                                //id="programa-customized-select"
                                id={props.id}
                                name="programa"
                            />}
                >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <Divider />
                    <MenuItem value={10}>+ 10</MenuItem>
                </Select>
            :
                <Fragment>
                    <FormControl>
                        <BootstrapInput2 className={classes.programa}
                                         //id="bootstrap-input"
                                         id={props.id}
                                         defaultValue={numero}
                                         disabled={props.disabled}
                                         onChange={cantidadIntroducida}
                        />
                    </FormControl>
                </Fragment>
            }
        </Fragment >
    )
}