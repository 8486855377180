import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { withTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden';

import ThemeGeroa from './../../components/ThemeGeroa.js'
import { useStore } from 'react-hookstore';

import BotonSinSalto from './../../components/botones/BotonSinSalto.js'

import auth from './../../apis/auth-helper.js'
import { listByUserCli, cancelaPedido } from './../../apis/api-pedido.js'
import { list } from './../../apis/api-producto.js'
import config from './../../config/config.js'

import { NumeroEsp, FechaLargaEsp, OrdenaArrayInverso } from './../../config/funcionesPropias.js'
import { PrecioCliente, TextoUnidad } from './../../config/funcionesParticulares.js'

import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl'

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const defaultImgProducto = require('./../../assets/images/defectos/producto_0000.png')
const defaultImgProductor = require('./../../assets/images/defectos/productor_0000.png')

const useStyles = makeStyles(theme => ({
    // color mas claro #caae6b
    // color Cabecera #BD9A46
    // color mas oscuro #846b31
    registro: {
        marginTop: '20px',
        minHeight: '65vh'
    },
    titulo: {
        display: "flex",
        flexFlow: "row",
        justifyContent: 'flex-start',
    },
    tituloLeft: {
        width: '30%',
        //border: "1px solid red",
    },
    tituloRight: {
        marginTop: '10px',
        width: '70%',
        textAlign: 'right',
        //border: "1px solid blue",
    },
    estado: {
        textAlign: 'left',
    },
    filtroPedidos: {
        width: '190px'
    },
    pista: {
        fontSize: 13,
        color: '#757575'
    },
    bordeGeneral: {
        //border: '1px solid #CFD8DC',
        borderRadius: '5px'
    },
    cabeceraPedido: {
        display: "flex",
        //flexFlow: "row",
        justifyContent: 'flex-start',
        [theme.breakpoints.up("xs")]: { flexFlow: "row" },
        [theme.breakpoints.down("xs")]: { flexFlow: "column" },
        //border: "1px solid magenta",
    },
    pedidoEstado: {
        color: '#846b31',
        fontWeight: 500,
        [theme.breakpoints.up("xs")]: { marginLeft: 20, display: "inline" },
        [theme.breakpoints.down("xs")]: { marginLeft: 0, display: "block" },
    },
    cabeceraPedidoLeft: {
        //width: '70%',
        [theme.breakpoints.up("xs")]: { width: '70%' },
        [theme.breakpoints.down("xs")]: { width: '100%' },
        //border: "2px solid blue",
    },
    cabeceraPedidoRight: {
        marginTop: '10px',
        marginBottom: '10px',
        //marginLeft: '70px',
        //width: '30%',
        [theme.breakpoints.up("xs")]: { width: '30%' },
        [theme.breakpoints.down("xs")]: { width: '100%' },
        // ¡¡Importante!! Esta linea despues de las anteriores. //
        textAlign: 'right',
        //border: "2px solid gray",
    },
    paneles: {
        display: "flex",
        flexFlow: "column",
        alignItems: "left",
        //border: "2px solid red"
    },
    panelProducto: {
        marginBottom: '10px',
        boxShadow: 'none', //'0px 0px 2px 3px solid lightGrey',
        //border: "2px solid red",
    },
    cabeceraPrincipal: {
        //border: "1px solid red",
    },
    lineasPedido: {
        display: "flex",
        flexFlow: "row",
        //border: "1px solid red",
    },
    cabeceraProducto: {
        display: "flex",
        //flexFlow: "row",
        //justifyContent: "space-between",
        //width: '100%',
        [theme.breakpoints.up("xs")]: { flexFlow: "row", justifyContent: "flex-start", width: "70%" },
        [theme.breakpoints.down("xs")]: { flexFlow: "column", justifyContent: "space-between", width: "100%" },
        //border: "1px solid green",
    },
    /* // Estába incluída dentro de la clase anterior
    cabeceraProductoDetalle: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "flex-start",
        width: '70%',
    },
    */
    fotoProducto: {
        //marginLeft: '-20px'
        marginLeft: '0px',
        maxHeight: 80,
        //border: "1px solid pink",
    },
    cabeceraProductoDatos: {
        //marginLeft: '20px',
        marginLeft: '10px',
        //border: "1px solid red",
    },
    cabeceraProductor: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "flex-start",
        width: '30%',
    },
    fotoProductor: {
        marginLeft: '10px'
    },
    cabeceraProductorDatos: {
        marginLeft: '20px',
        width: '100%',
        //border: "1px solid blue",
    },
}));

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(2),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 14,
        padding: '4px 24px 4px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


// Inicializa variables para renderizar
let pedidos = []
let pedidosFiltrados = []
// Valor por defecto al entrar (Pendientes)
let valorDefectoLista = 0

const MisPedidos = ({ t }) => {
    const classes = useStyles();
    //const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');
    const [valuesGlobales,] = useStore('DatosGlobales');
    const [filtro, setFiltro] = useState(valorDefectoLista)

    const [finCarga, setFinCarga] = useState(false)

    const [carga, setCarga] = useState(true)
    useEffect(() => {
        if (carga) {
            loadOrders()
        }
    })


    async function loadOrders() {
        const jwt = auth.isAuthenticated()
        // Busca los pedidos de 1 cliente (el que esta identificado)
        await listByUserCli({ userId: jwt.user.codigo }, { t: jwt.token }).then((data) => {
            //console.log('datos listByUserCli = ', data)
            if (data.error) {
                console.log(data.error)
            }
            else {
                //console.log("Mis Pedidos: data = ", data)
                loadProductos(data)
            }
        })
    }

    async function loadProductos(dataPedidos) {
        // Busca todos los productos
        await list().then((dataP) => {
            if (dataP.error) {
                console.log(dataP.error)
            }
            else {
                //console.log("datos productos: dataP = ", dataP)
                // Inicializar datos que se renderizan
                pedidosFiltrados = []
                pedidos = []
                // Ya estamos cargando
                setCarga(false)
                // Barre todos los Pedidos del Cliente (Usuario)
                dataPedidos.map((order, i) => {
                    order.total = importeTotalPedido(order)

                    // convertir gastos de caracter a número. // ?????? NaN
                    order.gastos = Number(order.gastos)

                    pedidos.push(order)
                    return null
                })

                pedidosFiltrados = filtraPedidos(valorDefectoLista)
                //console.log("Pedidos Filtrados: ", pedidosFiltrados)                
                // Fuerza Renderizado
                setFinCarga(!finCarga)
            }
        })
    }



    const pedidosAVer = [t("MisPedidos.SinPreparar"),
    //t("MisPedidos.Preparados"),
    t("MisPedidos.Entregados"),
    t("MisPedidos.Facturados"),
    t("MisPedidos.TodoslosPedidos")]


    function filtraPedidos(f) {
        switch (f) {
            case 0:
                return pedidos.filter(e => (e.estado === 'SinPreparar' || e.estado === 'Preparado'))
            case 1:
                return pedidos.filter(e => e.estado === 'Repartido')
            case 2:
                return OrdenaArrayInverso(pedidos.filter(e => e.estado === 'Facturado'), "fechaEntrega")
            case 3:
                return OrdenaArrayInverso(pedidos, "fechaEntrega")
            default:
                return []
        }
    }

    function importeTotalPedido(order) {
        // acumular el total
        let total = 0
        let cantidad = 0
        order.products.map((linea, i) => {
            cantidad = linea.cantidadServida === -1 ? linea.quantity : linea.cantidadServida
            total = total + cantidad * PrecioCliente(linea.price, linea.product.iva, valuesGlobales.ivaUsuario)
            return null
        })
        return total
    }


    const handleEventoFiltro = event => {
        valorDefectoLista = event.target.value
        pedidosFiltrados = filtraPedidos(event.target.value)
        // Renderizar
        setFiltro(event.target.value)
    }


    ////////////////////////
    // Cancelar el Pedido //
    ////////////////////////
    const cancelarPedido = index => event => {
       
        event.stopPropagation()
        let numPed = pedidosFiltrados[index].numero
        let restar = pedidosFiltrados[index].restaExistencias ? "1" : "0"
        let fechaPedido = pedidosFiltrados[index].created
        //
        cancelarUnPedido(numPed, restar, fechaPedido)
    }

    function sePuedeCancelar(productos){
        let retorno = true
        productos.forEach((pr) =>{
            if(pr.cantidadServida !== -1){
                retorno = false
            }
        })
        return retorno
    }


    async function cancelarUnPedido(numPed, restar, fechaPedido) {
        const jwt = auth.isAuthenticated()
        //
        //console.log("numPed:", numPed, "restar:", restar, "fecha:", fechaPedido)
        await cancelaPedido({ orderId: numPed, restaExistencias: restar, fechaPedido: fechaPedido }, { t: jwt.token }).then((data) => {
            //console.log('data = ', data)
            if (data.error) {
                console.log(data.error)
            }
            else {
                // Volver a Cargar Pedidos
                setCarga(true)
            }
        })
    }



    const listaPedidos = (
        pedidosFiltrados.length !== 0 ?
            pedidosFiltrados.map((pedido, i) => (
                <Accordion key={pedido._id + "pan" + i}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container className={classes.cabeceraPedido}>
                            <Grid item className={classes.cabeceraPedidoLeft}>
                                <Typography style={{ color: '#846b31', fontSize: 16, fontWeight: 500, marginRight: 10 }} display="inline">
                                    {t("MisPedidos.NumeroPedido")}:
                                </Typography>
                                <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500, marginRight: 10 }} display="inline">
                                    {pedido.numero}
                                </Typography>
                                <br />
                                <Typography style={{ color: '#846b31', fontSize: 16, fontWeight: 500, marginRight: 10 }} display="inline">
                                    {t("MisPedidos.FechaEntrega")}:
                                </Typography>
                                <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500, marginRight: 10 }} display="inline">
                                    {FechaLargaEsp(new Date(pedido.fechaEntrega), valuesGlobales.idioma)}
                                </Typography>
                                {/*
                                <Typography className={classes.pedidoEstado}>
                                    {t("MisPedidos."+pedido.estado)}
                                </Typography>
                                */}
                                <br />
                                <Typography style={{ color: '#846b31', fontSize: 16, fontWeight: 500, marginRight: 16 }} display="inline" >
                                    {t("MisPedidos.EntregaEn")}:
                                </Typography>
                                <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                    {pedido.tipoEntrega === "A domicilio" ?
                                        pedido.dir.direccion + " - " + pedido.dir.poblacion
                                        :
                                        pedido.puntoEntrega.nombre + " - " + pedido.puntoEntrega.localidad
                                    }
                                </Typography>
                                <br />
                                <Typography style={{ color: '#846b31', fontSize: 16, fontWeight: 500, marginRight: 16 }} display="inline" >
                                    {t("MisPedidos.FechaPedido")}:
                                </Typography>
                                <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                    {FechaLargaEsp(new Date(pedido.created), valuesGlobales.idioma)}
                                </Typography>
                                <br />
                                <Typography style={{ color: '#846b31', fontSize: 16, fontWeight: 500, marginRight: 10 }} display="inline">
                                    {t("MisPedidos.ImportePedido")}:
                                </Typography>
                                <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                    {NumeroEsp(pedido.total)}
                                    {pedido.gastos === 0 ? '' : ' + ' + t('MisPedidos.Gastos') + ' ' +
                                        /* // Gastos con Iva
                                        NumeroEsp(PrecioCliente(pedido.gastos, '001', valuesGlobales.ivaUsuario)) + ' = ' +
                                        NumeroEsp(pedido.total + PrecioCliente(pedido.gastos, '001', valuesGlobales.ivaUsuario))
                                        */
                                        // Gastos Sin Iva
                                        NumeroEsp(pedido.gastos) + ' = ' +
                                        NumeroEsp(pedido.total + pedido.gastos)
                                    } €
                                </Typography>
                            </Grid>
                            <Grid item className={classes.cabeceraPedidoRight} /*direction="column"*/>
                                {pedido.estado === 'SinPreparar' && sePuedeCancelar(pedido.products) ?
                                    <BotonSinSalto
                                        texto={t("MisPedidos.Cancelar")}
                                        accion={cancelarPedido(i)}
                                    />
                                    :
                                    <Fragment />
                                }
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <Divider />
                    {pedido.products.map((linea, i) => (
                        <Grid item key={pedido._id + "Art" + linea.producto + i} className={classes.lineasPedido}>
                            <Grid item className={classes.cabeceraProducto}>
                                {/*<Grid item className={classes.cabeceraProductoDetalle}>*/}
                                <Grid item className={classes.fotoProducto}>
                                    <img alt={valuesGlobales.idioma === "Es" ? linea.product.description : linea.product.description2} width="80px" height="80px"
                                        src={config.misImagenes + '/productos/' + linea.product.name + '.jpg'}
                                        onError={(event) => event.target.setAttribute("src", defaultImgProducto)}
                                    />
                                </Grid>
                                <Grid item className={classes.cabeceraProductoDatos}>
                                    <Typography style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                        {valuesGlobales.idioma === "Es" ? linea.product.description : linea.product.description2}
                                    </Typography>
                                    <br />
                                    <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                        ({linea.cantidadServida !== -1 ? linea.cantidadServida : linea.quantity} {TextoUnidad(linea.product.unidad)} x {NumeroEsp(PrecioCliente(linea.price, linea.product.iva, valuesGlobales.ivaUsuario), -1)} € = {NumeroEsp((linea.cantidadServida !== -1 ? linea.cantidadServida : linea.quantity) * PrecioCliente(linea.price, linea.product.iva, valuesGlobales.ivaUsuario))} €)
                                    </Typography>
                                </Grid>
                                {/*</Grid>*/}
                            </Grid>
                            <Hidden xsDown>
                                <Grid item className={classes.cabeceraProductor}>
                                    <Grid item className={classes.fotoProductor}>
                                        <img alt={linea.shop.description} width="80px" height="80px"
                                            src={config.misImagenes + '/shops/' + linea.shop.name + '.jpg'}
                                            onError={(event) => event.target.setAttribute("src", defaultImgProductor)}
                                        />
                                    </Grid>
                                    <Grid item className={classes.cabeceraProductorDatos}>
                                        <Typography style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                            {linea.shop.owner.direc[0].poblacion}
                                        </Typography>
                                        <br />
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                            {linea.shop.owner.direc[0].provincia}
                                        </Typography>
                                        <br />
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                            {linea.shop.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    ))}
                </Accordion>
            ))
            :
            <Fragment>
                <Typography style={{ color: '#846b31', fontSize: 18, fontWeight: 500, marginLeft: 40 }} display="inline" >
                    {t("MisPedidos.NoHay")}
                </Typography>
            </Fragment>
    )


    const cabeceraListaPedidos = (
        <Fragment>
            <Grid container className={classes.titulo}>
                <Grid item className={classes.tituloLeft}>
                    <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600, marginLeft: 2, marginRight: 10 }} display="inline">
                        {t("MisPedidos.Pedidos")}
                    </Typography>
                    <br />
                </Grid>
                <Grid item className={classes.tituloRight} /*direction="column"*/>
                    <FormControl className={classes.estado}
                        onClick={event => event.stopPropagation()}
                        onFocus={event => event.stopPropagation()}
                    >
                        <Select
                            value={valorDefectoLista}
                            onChange={handleEventoFiltro}
                            input={<BootstrapInput className={classes.filtroPedidos} id="programa-customized-select" />}
                        >
                            <MenuItem value={0}>
                                <em className={classes.pista}>
                                    {t("MisPedidos.Seleccione")} ...
                                </em>
                            </MenuItem>
                            {pedidosAVer.map((opcion, i) => (
                                <MenuItem key={"Ped_" + i} className={classes.pista} value={i}>{opcion}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
        </Fragment>
    )

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Fragment>
                <Container maxWidth="md" className={classes.registro}>
                    {cabeceraListaPedidos}
                    {filtro === 0 ? listaPedidos : <Fragment />}
                    {filtro === 1 ? listaPedidos : <Fragment />}
                    {filtro === 2 ? listaPedidos : <Fragment />}
                    {filtro === 3 ? listaPedidos : <Fragment />}
                    {filtro === 4 ? listaPedidos : <Fragment />}
                </Container>
                <br />
            </Fragment>
        </MuiThemeProvider>
    );
}


export default withTranslation()(MisPedidos)