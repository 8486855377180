import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';

import Popper from "@material-ui/core/Popper"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import MenuList from "@material-ui/core/MenuList"
import Fade from '@material-ui/core/Fade'

import { useTranslation } from 'react-i18next'
import { useStore} from 'react-hookstore';

import ThemeGeroa from '../ThemeGeroa.js'

const useStyles = makeStyles(theme => ({
    botonHeaderSelectIdiomas: {
        textTransform: "none",
        fontSize: "16px",
        [ThemeGeroa.breakpoints.down('md')]: { fontSize: '13px' },
        padding: "0px 10px 0px 10px !important",
        [ThemeGeroa.breakpoints.down('md')]: { padding: '0px 5px 0px 5px !important' },
        fontWeight: "400",
        color: "#FFF",
        backgroundColor: "transparent",
        maxHeight: '30px',
        minWidth: '90px',
        minHeight: '30px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "transparent",
            cursor: "hand",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        "&:disabled": {
            backgroundColor: "transparent",
        },
    },
}));

export default function BotonHeaderSelectIdiomas({ ...props }) {
    const classes = useStyles();

    // Parámetros recibidos: Eemplo de llamada:
    //        <BotonHeaderSelectSinExpand
    //           texto='Volver'                     // Obligatorio
    //           opciones={miLista}                 // Obligatorio
    //           opcion seleccionada={1}            // Obligatorio
    //        >
    //        </BotonHeaderSelectSinExpand>
    //
    //              Donde    let miLista = []
    //                           miLista[0] = { "texto": "Es", "habilitado": true}
    //                           miLista[1] = { "texto": "Eus", "habilitado": true}
    //                           miLista[2] = { "texto": "Eng", "habilitado": true}
    //                                                    Las 3 letras deben coincidir con el nombre del Fichero

    // Coge el idioma ultimo que haya utilizado en la página el usuario en este ordenador
    const [parametros, setParametros] = useState({
         seleccion: props.opcionSeleccionada
    })

    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');

    const [anchorEl, setAnchorEl] = useState(null);
    const [, setIndex] = useState()
    const [open, setOpen] = useState(false)

    function handleToggle(event) {
        setAnchorEl(event.currentTarget)
        setOpen(!open);
    };

    function handleClose(event) {
        if(open) {
            setOpen(!open);
        }
    }

    const {i18n} = useTranslation()

    function handleMenuItemClick(i) {
        setIndex(i)
        setOpen(!open);

        let sel = props.opciones[i].texto
        setParametros({ ...parametros, seleccion: sel})
     
        i18n.changeLanguage(sel)
        
        localStorage.setItem('idioma', sel)

        setValuesGlobales({...valuesGlobales, idioma: sel})
    }

    // Lista de opciones
    let miListaOpciones = props.opciones.map((opcion, index) => (
        <MenuItem
            className={classes.subMenu}
            key={index}
            disabled={!opcion.habilitado}
            onClick={event => handleMenuItemClick(index)}
        >
           {opcion.texto}
        </MenuItem>
    ))

    return (
        <Fragment>
            <Button className={classes.botonHeaderSelectIdiomas}
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                transitioncomponent={Fade}
            >
                {parametros.seleccion}
            </Button>
            <Popper open={open} anchorEl={anchorEl} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    {miListaOpciones}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>
    )
};
