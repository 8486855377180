import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
//import Typography from '@material-ui/core/Typography';

//import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

//import { MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import ThemeGeroa from '../ThemeGeroa.js'


import { withTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    botonMenuInicio: {
        marginTop: '0px',
        marginRight: '8px',
        marginLeft: '0.9rem',
        color: '#FFF'
    },
    boton: {
        textTransform: "none",
        fontSize: "16px",
        [ThemeGeroa.breakpoints.down('md')]: { fontSize: '13px' },
        padding: "0px 10px 0px 10px !important",
        [ThemeGeroa.breakpoints.down('md')]: { padding: '0px 5px 0px 5px !important' },
        fontWeight: "400",
        color: "#FFF",
        backgroundColor: "transparent",
        maxHeight: '30px',
        minWidth: '90px',
        minHeight: '30px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "transparent",
            cursor: "hand",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        "&:disabled": {
            backgroundColor: "transparent",
        },
    },
    icono: {
        marginTop: '3px',
        color: '#FFF'
    },
}));


const BotonPdfAyuda = ({ t, ...props }) => {
    const classes = useStyles();
    return (
        <a
            className={classes.root}
            href={props.fichero}
            target="_blank"
            rel="noopener noreferrer"
            download
        >
            <Grid item className={classes.botonMenuInicio}>
                <Button className={classes.boton}>
                    {t('Cabecera.Ayuda')}
                </Button>
            </Grid>
            <Grid item className={classes.icono}>
                <PictureAsPdfIcon />
            </Grid>
        </a >
    )
}

export default withTranslation()(BotonPdfAyuda)