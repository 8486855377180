import React, { Fragment } from "react";
// material-ui
import Container             from '@material-ui/core/Container';
import Divider               from '@material-ui/core/Divider'
import Grid                  from '@material-ui/core/Grid';
import { MuiThemeProvider }  from '@material-ui/core/styles';
// react-...
import { withTranslation }  from 'react-i18next'
import { useStore}          from 'react-hookstore';
import { Span }             from 'react-html-email'
// componentes
import ThemeGeroa   from '../../components/ThemeGeroa.js'
// apis
//import {read}       from '../../apis/api-pedido.js'
// config
import config from '../../config/config.js'
import {PrecioCliente} from '../../config/funcionesParticulares.js'
import {NumeroEsp, FechaLargaEsp, BuscaIndice} from '../../config/funcionesPropias.js'

import { Email} from 'react-html-email';
///import { FormatPaintRounded, OndemandVideoOutlined } from "@material-ui/icons";
/*
// definir estilo en una constante. //
const textDefaults = {
    fontFamily: 'Verdana',
    fontSize: 42,
    fontWeight: 'bold',
    color: 'orange',
  }
// coger el estilo de una constante. //
<Span {...textDefaults}>
    {t("MisProductos.UltimoPedido")}
</Span>
*/
// constantes con estilos de letras. //
const texto32 = {color: '#016647',
                 fontSize: 32,
                 fontWeight: '600'}
const texto18 = {color: '#016647',
                 fontSize: 18,
                 fontWeight: '600'}                 
const texto12 = {color: '#95c11e',
                 fontSize: 12,
                 fontWeight: '600'}                 
const dato14 = {color: '#616161',
                fontSize: 14,
                fontWeight: '500'}                 
const dato15 = {color: '#c62828',
                fontSize: 14,
                fontWeight: '500'}                 

const EmailPedidoUsuario = ({t, ...params}) => {
    const [valuesGlobales] = useStore('DatosGlobales');

    const Pedido = params.pedido
    const imagen = config.misImagenes+'/mercados/mer'+ Pedido.mercado +'.jpg'
    
    /*
    // Función que calcula el total del pedido. //
    // defino: (()=>{}) y llamo: () a la función. //
    Pedido.total = (() => {
        let total = 0
        let fin = Pedido.products.length
        for (var i=0;i<fin;i++) {
            let linea = Pedido.products[i]
            total += linea.quantity * PrecioCliente(linea.price, linea.iva, valuesGlobales.ivaUsuario)
        }
        return total
    })()
    */

    // Busca el nombre del mercado
    let tp = BuscaIndice(valuesGlobales.mercados, 'name', Pedido.mercado)
    Pedido.nombreMercado = tp === -1 ? '' : valuesGlobales.mercados[tp].description
    // Busca el corordinador del mercado
    let tp1 = BuscaIndice(valuesGlobales.mercados[tp].coordinadores, 'defecto', true)
    Pedido.coordinador = tp1 === -1 ? '' : valuesGlobales.mercados[tp].coordinadores[tp1]

    // Dependiendo del tipo de Entrega: "0". "A domicilio". //
    let txtDir1 = Pedido?.dir?.direccion
    let txtDir2 = Pedido?.dir?.poblacion
    let txtDir3 = Pedido?.dir?.provincia
    // tipoDeEntrega: "1". "En punto de entrega". //
    if (Pedido.tipoDeEntrega === "1") {
        txtDir1 = Pedido?.puntoEntrega?.nombre
        txtDir2 = Pedido?.puntoEntrega?.localidad
        txtDir3 = Pedido?.puntoEntrega?.detalle
    }
    let fechaEntrega = ""
    let pie = ""

    const miPedido = (
        <Fragment>
            <Grid container>
                <table width='100%'>
                    <tr>
                        <td width='15%'>
                            <Span {...texto18}>
                                {t("Email.Cliente") }:
                            </Span>
                        </td>
                        <td width='40%'>
                            <Span {...dato14}>
                                {Pedido.customer_name}
                            </Span>
                        </td>
                        <td align='left'
                            width='45%'>
                            <Span {...texto18}>
                                {t("Email.Lugar")} 
                            </Span>
                        </td>
                    </tr>
                </table>
                <table width='100%'>
                    <tr>
                        <td width='15%'>
                            <Span {...texto18}>
                                {t("Email.PedidoWeb") }:
                            </Span>
                        </td>
                        <td width='40%'>
                            <Span {...dato14}>
                                {Pedido._id}
                            </Span>
                        </td>
                        <td width='45%'>
                            <Span {...dato14}>
                                {txtDir1}
                            </Span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Span {...texto18}>
                                {t("Email.FechaPedido")}:
                            </Span>
                        </td>
                        <td>
                            <Span {...dato14}>
                                {FechaLargaEsp(new Date(Pedido.fecha), valuesGlobales.idioma)}
                            </Span>
                        </td>
                        <td>
                            <Span {...dato14}>
                                {txtDir2}
                            </Span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Span {...texto18}>
                                {t("Email.TotalPedido")}:
                            </Span>
                        </td>
                        <td>
                            <Span {...dato14}>
                                {NumeroEsp(Pedido.ivaUsuario === 0 ? Pedido.totalSinIva : Pedido.totalConIva)} €
                            </Span>
                        </td>
                        <td>
                            <Span {...dato14}>
                                {txtDir3}
                            </Span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Span {...texto18}>
                                {t("Email.FormaPago")}:
                            </Span>
                        </td>
                        <td>
                            <Span {...dato14}>
                                {Pedido.nombreFPago}
                            </Span>
                        </td>
                    </tr>
                </table>
            </Grid>
            <Divider />
            <Grid container>
                <table width='100%'>
                    <tr>
                        <th width='60%'
                            align='left'>
                            <Span {...texto18}>
                                {t("Email.Descripcion") }
                            </Span>
                        </th>
                        <th width='10%'
                            align='right'>
                            <Span {...texto18}>
                                {t("Email.Cantidad")}
                            </Span>
                        </th>
                        <th width='15%'
                            align='right'>
                            <Span {...texto18}>
                                {t("Email.Precio")}
                            </Span>
                        </th>
                        <th width='15%'
                            align='right'>
                            <Span {...texto18}>
                                {t("Email.Total")}
                            </Span>
                        </th>
                    </tr>
                    {Pedido.products
                        .sort((p1, p2) =>{
                            let ret = 0
                            if (p1.fechaEntrega > p2.fechaEntrega) {ret = 1}
                            if (p1.fechaEntrega < p2.fechaEntrega) {ret = -1}
                            return ret
                        })
                        .map((linea, i) => {
                            let cabecera = <div/>
                            if (fechaEntrega !== linea.fechaEntrega ) {
                                cabecera = (
                                    <Span {...dato15}>
                                        <br/>
                                        ({t("Email.FechaEntrega")}: {FechaLargaEsp(new Date(linea.fechaEntrega), valuesGlobales.idioma)})
                                    </Span>
                                )
                                fechaEntrega = linea.fechaEntrega
                                pie = <div/>
                            }
                            // Pie con Gastos
                            if(Pedido.products[i+1] !== undefined) {
                                if(Pedido.products[i+1].fechaEntrega !== Pedido.products[i].fechaEntrega){
                                    if (Pedido.gastos !== 0) {
                                        formaPie(Pedido)
                                    }
                                }
                            }
                            else {
                                if (Pedido.gastos !== 0) {
                                    formaPie(Pedido)
                                }
                            }
                            return (
                            <div key={i}>
                                <tr>
                                    <td align='left'>
                                        {cabecera}
                                    </td>
                                </tr>
                                <tr>
                                    <td align='left'>
                                        <Span {...dato14}>
                                            {linea.description}
                                        </Span>
                                    </td>
                                    <td align='right'>
                                        <Span {...dato14}>
                                            {NumeroEsp(linea.quantity, -1)}  {linea.unidad}
                                        </Span>
                                    </td>
                                    <td align='right'>
                                        <Span {...dato14}>
                                            {NumeroEsp(PrecioCliente(linea.price, linea.iva, Pedido.ivaUsuario), -1)} €
                                        </Span>
                                    </td>
                                    <td align='right'>
                                        <Span {...dato14}>
                                            {NumeroEsp(linea.quantity * PrecioCliente(linea.price, linea.iva, Pedido.ivaUsuario))} €
                                        </Span>
                                    </td>
                                </tr>
                                {pie}
                            </div>
                            )
                        }
                    )}
                </table>
            </Grid>
            <Divider />
            <Grid container>
                <table width='100%'>
                    <tr>
                        <td width='75%'>
                            <Span {...texto18}>
                                {t("Email.TotalPedido")+" - "}
                            </Span>
                            <Span {...texto12}>
                                {"(" }
                                {Pedido.ivaUsuario === 0 ? t("Email.IvaNoIncluido") : t("Email.IvaIncluido")}
                                {")"}
                            </Span>
                        </td>
                        <td width='25%' align='right'>
                            <Span {...dato14}>
                            {NumeroEsp(Pedido.ivaUsuario === 0 ? Pedido.totalSinIva : Pedido.totalConIva)} €
                            </Span>
                        </td>
                    </tr>
                </table>
            </Grid>
            <Divider />
            <br/>
            {Pedido.repeticiones.length > 0 &&
            <Grid container>
                <table width='100%'>
                    <tr>
                        <td width='100%'>
                            <Span {...dato15}>
                                {t("Email.Repeticion")+": "}
                                {Pedido.repeticiones.map(f=>FechaLargaEsp(f.fecha, valuesGlobales.idioma)).join('; ')}
                            </Span>
                        </td>
                    </tr>
                </table>
            </Grid>
            }
            <br/>
            <Grid container>
                <table width='100%'>
                    <tr>
                        <td width='100%'>
                            <Span {...texto12}>
                                {t("Email.TextoPlataforma3")}
                            </Span>
                        </td>
                    </tr>
                </table>
            </Grid>
        </Fragment>
    )
    

    function formaPie(pedido){
        //pedido.total += PrecioCliente(pedido.gastos, '001', pedido.ivaUsuario)
        pie = (
            <tr>
                <td align='left'>
                    <Span {...dato14}>
                        {t("Email.GastosReparto")}
                    </Span>
                </td>
                <td align='right'>
                 </td>
                <td align='right'>
                 </td>
                <td align='right'>
                    <Span {...dato14}>
                        {/*NumeroEsp(PrecioCliente(pedido.gastos, '001', pedido.ivaUsuario))*/}
                        {NumeroEsp(PrecioCliente(pedido.gastos, '004', pedido.ivaUsuario))} €
                    </Span>
                </td>
            </tr>
        )
    }



    return (
        <Email title='pedido'>
        <MuiThemeProvider theme={ThemeGeroa}>
            <Fragment>
                <Container>
                    <table width='100%'>
                        <tr>
                            <td align='left'
                                width='50%'>
                                <img width='250'
                                    alt = ""
                                    src={imagen}
                                />
                            </td>
                            <td align='right'
                                width='50%'>
                            </td>
                        </tr>
                    </table>
                    <br/>
                    <table width='100%'>
                        <tr>
                            <td align='left'>
                                <Span {...texto12}>
                                    {t("Email.TextoPlataforma1")}
                                    {Pedido.nombreMercado}
                                    {t("Email.TextoPlataforma2")}
                                    {Pedido.coordinador.nombre}
                                    {Pedido.coordinador.telefono !== "" ? ", "+Pedido.coordinador.telefono : ""}
                                    {", "}{Pedido.coordinador.email}
                                    {"."}
                                </Span>
                            </td>
                        </tr>
                    </table>
                    <br/>
                    <br/>
                    <Span {...texto32}>
                        {t("Email.Pedido")} 
                    </Span>
                    <br/>
                    {miPedido}
                    <br/>
                </Container>
            </Fragment>
        </MuiThemeProvider>
        </Email>
    );
}

export default withTranslation()(EmailPedidoUsuario)