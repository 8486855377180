import config from './../config/config.js'


const rutaApi = config.miServidor + '/users'


const listUserEmail = (params) => {
  return fetch(rutaApi + '/listUserEmail', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)

  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))
}





const listUserUsuario = (params) => {
  return fetch(rutaApi + '/listUserUsuario', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)

  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))
}





const updatePassword = (user, pass) => {
  return fetch(rutaApi + '/updatePassword', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      u: user,
      p: pass
    })
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}



const crearCliente = (cliente) => {
  return fetch(rutaApi + '/crearCliente/', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      cliente
    })
  })
    .then((response) => {
      return response.json()
    }).catch((err) => console.log(err))
}

const crearDirec = (codigo, credentials, direc) => {
  return fetch(rutaApi + '/crearDirec/', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    },
    body: JSON.stringify({
      codigo: codigo,
      direc
    })
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}

const updateCliente = (codigo, credentials, cliente) => {
  //console.log('en la api = ', codigo, cliente)
  return fetch(rutaApi + '/updateCliente/', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + credentials.t
    },
    body: JSON.stringify({
      codigo: codigo,
      cliente
    })
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}



export {
  crearCliente,
  crearDirec,
  listUserEmail,
  listUserUsuario,
  updatePassword,
  updateCliente
}
