import React, { Fragment } from "react";
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles'
import ThemeGeroa from '../../components/ThemeGeroa.js'
import { withTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    registro: {

        marginTop: '20px',
        minHeight: '65vh'
    },
    boton: {
        color: '#FFF',
        backgroundColor: '#1b5e20',
        textTransform: "none",
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "#4caf50",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
    },
}));



function abreCorreo(event, email) { 
    event.stopPropagation();
    event.preventDefault();
    // Abrir el programa de correo electrónico. //
    window.location = "mailto:"+ email
}




const Condiciones = ({t}) => {
    const classes = useStyles();
    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Fragment>
                <CssBaseline />
                <Container maxWidth="md" className={classes.registro} >
                    <Typography align="center" style={{ fontSize: '32px', color: '#487e4c', fontWeight: 700, marginTop: 20, marginBottom: 18 }}>
                        {t("Condiciones.L1")}
                    </Typography>
                    <Grid item style={{ marginTop: 1, marginBottom: 1 }}>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Condiciones.L2")}
                        </Typography>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L3")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L4")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Condiciones.L5")}
                        </Typography>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L6")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ textDecoration:'underline', color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L7")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L8")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L9")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                            {t("Condiciones.L10")}
                        </Typography>
                        <Typography display="inline"
                                    onClick={e => abreCorreo(e, "admin@ekolurra")}
                                    style={{ color: 'blue',
                                              fontSize: '16px',
                                              fontWeight: 400,
                                              textDecoration:'underline',
                                              }}>
                            {"admin@ekolurra.eus"}
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L11")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L12")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Condiciones.L13")}
                        </Typography>
                        <Typography align= "justify" style={{  fontStyle: 'italic', color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L14")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L15")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L16")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L17")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Condiciones.L18")}
                        </Typography>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L19")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L20")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L21")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L22")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Condiciones.L23")}
                        </Typography>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L24")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L25")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L26")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L27")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Condiciones.L28")}
                        </Typography>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L29")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L30")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L31")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L32")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Condiciones.L33")}
                        </Typography>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L34")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L35")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L36")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L37")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Condiciones.L38")}
                        </Typography>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L39")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L40")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L41")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L42")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Condiciones.L43")}
                        </Typography>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L44")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Condiciones.L45")}
                        </Typography>
                        <br/>
                        <Typography style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="https://webgate.ec.europa.eu/odr/main/?event=main.home.show" target="_blank" rel="noopener noreferrer">
                                {t('Condiciones.L46')}
                             </a>
                        </Typography>
                        <br/>
                        <br/>           
                    </Grid>
                    <Grid item className={classes.plantilla}>

                    </Grid>
                </Container>
            </Fragment>
            
        </MuiThemeProvider >
    );
}

export default withTranslation()(Condiciones)

