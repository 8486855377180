import React, { Fragment } from "react";
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles'
import ThemeGeroa from '../../components/ThemeGeroa.js'
import { withTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    registro: {

        marginTop: '20px',
        minHeight: '65vh'
    },
    boton: {
        color: '#FFF',
        backgroundColor: '#1b5e20',
        textTransform: "none",
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "#4caf50",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
    },
}));



function abreCorreo(event, email) { 
    event.stopPropagation();
    event.preventDefault();
    // Abrir el programa de correo electrónico. //
    window.location = "mailto:"+ email
}




const AvisoLegal = ({t}) => {
    const classes = useStyles();
    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Fragment>
                <CssBaseline />
                <Container maxWidth="md" className={classes.registro} >
                    <Typography align="center" style={{ fontSize: '32px', color: '#487e4c', fontWeight: 700, marginTop: 20, marginBottom: 18 }}>
                        {t("AvisoLegal.L1")}
                    </Typography>
                    <Grid item style={{ marginTop: 1, marginBottom: 1 }}>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("AvisoLegal.L2")}
                        </Typography>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                            {t("AvisoLegal.L3")}
                        </Typography>
                        <Typography display="inline"
                                    onClick={e => abreCorreo(e, "admin@ekolurra")}
                                    style={{ color: 'blue',
                                              fontSize: '16px',
                                              fontWeight: 400,
                                              textDecoration:'underline',
                                              }}>
                            {"admin@ekolurra.eus"}
                        </Typography>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                            {t("AvisoLegal.L4")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L5")}
                        </Typography>
                        <Typography style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20 }}>
                            <a href="https://www.sareko.net" target="_blank" rel="noopener noreferrer">
                                {t('AvisoLegal.L6')}
                            </a>
                        </Typography>
                        <Typography style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            <a href="https://www.ekolurra.eus" target="_blank" rel="noopener noreferrer">
                                {t('AvisoLegal.L7')}
                            </a>
                        </Typography>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L8")}
                        </Typography>
                        <Typography style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            <a href="https://www.facebook.com/ekolurra" target="_blank" rel="noopener noreferrer">
                                {t('AvisoLegal.L9')}
                            </a>
                        </Typography>
                        <Typography style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            <a href="https://twitter.com/ekolurra" target="_blank" rel="noopener noreferrer">
                                {t('AvisoLegal.L10')}
                            </a>
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L11")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L12")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L13")}
                        </Typography>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L14")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("AvisoLegal.L15")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L16")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L17")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L18")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L19")}
                        </Typography>
                        <br/>
                        <Typography style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            <a href="https://www.facebook.com/legal/terms" target="_blank" rel="noopener noreferrer">
                                {t('AvisoLegal.L20')}
                            </a>
                        </Typography>
                        <Typography style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            <a href="https://help.instagram.com" target="_blank" rel="noopener noreferrer">
                                {t('AvisoLegal.L21')}
                            </a>
                        </Typography>
                        <Typography style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            <a href="https://twitter.com/es/tos" target="_blank" rel="noopener noreferrer">
                                {t('AvisoLegal.L22')}
                            </a>
                        </Typography>
                        <Typography style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            <a href="https://www.linkedin.com/legal/user-agreement" target="_blank" rel="noopener noreferrer">
                                {t('AvisoLegal.L23')}
                            </a>
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("AvisoLegal.L24")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L25")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20 }}>
                            {t("AvisoLegal.L26")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            {t("AvisoLegal.L27")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            {t("AvisoLegal.L28")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L29")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            {t("AvisoLegal.L30")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            {t("AvisoLegal.L31")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            {t("AvisoLegal.L32")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            {t("AvisoLegal.L33")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            {t("AvisoLegal.L34")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            {t("AvisoLegal.L35")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                            {t("AvisoLegal.L36")}
                        </Typography>
                        <Typography display="inline"
                                    onClick={e => abreCorreo(e, "admin@ekolurra")}
                                    style={{ color: 'blue',
                                              fontSize: '16px',
                                              fontWeight: 400,
                                              textDecoration:'underline',
                                              }}>
                            {"admin@ekolurra.eus"}
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L37")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("AvisoLegal.L38")}
                        </Typography>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L39")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L40")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L41")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L42")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L43")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("AvisoLegal.L44")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                             {t("AvisoLegal.L45")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             {t("AvisoLegal.L46")}
                        </Typography>
                        <br/>
                        <br/>
                        <br/>           
                    </Grid>
                </Container>
            </Fragment>
        </MuiThemeProvider >
    );
}

export default withTranslation()(AvisoLegal)

