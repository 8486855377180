import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import ReactImageMagnify from 'react-image-magnify';

export default function AmpliaImagen({ ...props }) {
    //const [repinta, setRepinta] = useState(false)
    const [datos, setDatos] = useState({nombre: "",
                                        peqAlto: 80,
                                        ancho: 800,
                                        alto: 800,
                                        enlargedAncho: '400%',
                                        enlargedAlto: '400%'
                                        })
    useEffect(() => {
        let montado = true
        
        async function leeImagen()  {
            let imagen = new Image()
    
            imagen.onload = function() {
                if (montado) {
                    const img = {...datos}
                    img.nombre = imagen.src
                    // Tamaños reales de la foto
                    let height = imagen.height
                    let width = imagen.width
                
                    // Tamaños para imagen grande
                    img.alto = height * (datos.ancho/width)
        
                    // Tamaños para la imagen pequeña
                    img.peqAlto = props.tamHeight ? props.tamHeight : height * (props.tamWidth/width)
                    
                    if(img.peqAlto >= 100){
                        img.enlargedAncho = "400%"
                        img.enlargedAlto = "300%"
                    } else if(img.peqAlto >= 80){
                        img.enlargedAncho = "400%"
                        img.enlargedAlto = "400%"
                    } else if(img.peqAlto >= 60){
                        img.enlargedAncho = "500%"
                        img.enlargedAlto = "500%"
                    } else if(img.peqAlto >= 40){
                        img.enlargedAncho = "500%"
                        img.enlargedAlto = "600%"
                    } else {
                        img.enlargedAncho = "600%"
                        img.enlargedAlto = "700%"
                    }
                    // setRepinta( repinta => !repinta )
                    setDatos( img )
                }
            }

            imagen.onerror = function() {
                if (montado) {
                    datos.nombre = props.defaultImg
                   // setRepinta( repinta => !repinta )
                }
            }

            imagen.src = props.fotoPeq
        }
        // Cargar Datos. //
        leeImagen()

        // Función para evitar el error: Can't perform a React state update on an unmounted component, //
        return function cleanup() {
            montado = false
        }

        // El array vacío indica que sólo se se llama a useEffect 1 vez.
        // https://es.reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
    },  [props.fotoPeq, props.defaultImg, props.tamHeight, props.tamWidth])


    return (
        <Fragment>
            <Grid>
                <ReactImageMagnify {...{
                    lensStyle: {
                        background: 'hsla(0, 0%, 100%, .3)',
                        border: '1px solid #ccc'
                    },
                    smallImage: {
                        width: props.tamWidth, //(required if isFluidWidth is not set)
                        height: datos.peqAlto, //(required if isFluidWidth is not set)
                        //isFluidWidth: true,
                        src: datos.nombre+"?" + new Date().getTime()
                    },
                    largeImage: {
                        src: datos.nombre+"?" + new Date().getTime(),
                        width: datos.ancho,
                        height: datos.alto

                    },
                    enlargedImageContainerDimensions: {
                        width: datos.enlargedAncho,
                        height: datos.enlargedAlto
                    },
                    shouldUsePositiveSpaceLens: true,
                    enlargedImagePosition: "beside",
                    enlargedImageContainerStyle: {
                        zIndex: "1"
                    }
                }} />
            </Grid>
         </Fragment>
    )
}