import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {Link } from "react-router-dom"
import ThemeGeroa from '../ThemeGeroa.js'
import CartIcon from "@material-ui/icons/ShoppingCart"
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton'




export default function BotonCarrito({ ...props }) {


    // Parámetros recibidos: Eemplo de llamada:
    //        <BotonCarrito
    //           salto='/Inicio'                    // Obligatorio
    //           numero='7'                         // Obligatorio
    //           desactivado={true}                 // Opcional
    //        >
    //        </BotonCarrito>
    //
    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <IconButton aria-label="Carrito de la Compra" color="inherit"
                component={Link} to={props.salto}
                disabled={props.desactivado || props.salto === ''}
                style={{color:'white'}}
            >
                <Badge badgeContent={props.numero} color="secondary" overlap="circular">
                    <CartIcon />
                </Badge>
            </IconButton>
        </MuiThemeProvider>
    )
}