import React, { Fragment, useState } from 'react';
import { useStore } from 'react-hookstore';

import Badge from '@material-ui/core/Badge';
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Divider from '@material-ui/core/Divider'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid';
import Grow from "@material-ui/core/Grow"
import IconButton from '@material-ui/core/IconButton'
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import Typography from '@material-ui/core/Typography';

import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

import { makeStyles } from '@material-ui/styles';

import ThemeGeroa from '../ThemeGeroa.js'

import CloseWindowIcon from './../../assets/images/icons/close_grey_18x18.png'
import LurraIcon from './../../assets/images/icons/lurra.png'

const useStyles = makeStyles(theme => ({
    botonHeaderSelect: {
        textTransform: "none",
        fontSize: "16px",
        [ThemeGeroa.breakpoints.down('md')]: { fontSize: '13px' },
        padding: "0px 10px 0px 10px !important",
        [ThemeGeroa.breakpoints.down('md')]: { padding: '0px 5px 0px 5px !important' },
        fontWeight: "400",
        color: "#FFF",
        backgroundColor: "transparent",
        maxHeight: '30px',
        minWidth: '90px',
        minHeight: '30px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "transparent",
            cursor: "hand",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        "&:disabled": {
            backgroundColor: "transparent",
        },
    },
    panelNotificacion:{
        //border: "solid red 1px"
        maxWidth: 320,
        marginLeft: 4,
        marginTop: 0,
        alignItems: "top",

    },
    messageTituloCaja:{
        marginTop: '22px'
    },
    lurra: {
        marginLeft: '20px',
        marginTop: '14px'
    },
    closeIcon: {
        marginRight: '5px',
        marginTop: '5px',
    },
    messageBox: {
        marginTop: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    messageHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '10px',
        marginLeft: '0px'
    },
}));

export default function BotonNotificaciones({ ...props }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState();
    const [placement, setPlacement] = useState();

    const [open, setOpen] = useState(false)
    const [leido, setLeido] = useState()
    const [valuesGlobales, setValuesGlobales ] = useStore('DatosGlobales')

    const handleToggle = newPlacement => event => {
        setAnchorEl(event.currentTarget);
        setOpen(prev => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
        setLeido(props.notificaciones)
    };

    function handleClose(event) {
        if(open) {
            setOpen(!open);
        }
    }

    function borrarNotificacion(i){
        console.log('Borrada ' , i)
        // Registrar en la base de datos que el mensaje se ha borrado para este usuario
        // Api sin hacer


        // Retirar el mensaje de las variables globales
        let not = props.notificaciones
        not.splice(i, 1)

        setValuesGlobales({ ...valuesGlobales, notificaciones: not})
       
         // Refrescar la pantalla
        setLeido(not)

    } 


    let miListaOpciones = (
        leido !== undefined ?
        <Fragment>
            {/*props.notificaciones.map((notificacion, index) => (*/
             leido.map((notificacion, index) => (
                <Grid className={classes.panelNotificacion}>
                    {index === 0 ?
                        <Fragment>
                            <Grid className={classes.messageHeader}>
                                <Grid item className={classes.lurra}>
                                    <img width="40px"
                                        src={LurraIcon}
                                        alt={"lurra"}
                                    />
                                </Grid>
                                <Grid className={classes.messageTituloCaja}>
                                    <Typography style={{ fontSize: 24, fontWeight: 600, color: ' #487e4c', marginLeft: 10, marginTop: 30 }} display="inline">
                                        {"Tus Mensajes"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    :
                        <Fragment/>
                    }
                    <Grid item className={classes.messageBox}>
                        <Grid item style={{ marginTop: 0 }} >
                            <Grid container direction="row" justify="flex-end" alignItems="center">
                                <Grid item className={classes.closeIcon}>
                                    <img src={CloseWindowIcon}
                                        alt={"cerrar"}
                                        onClick={event => borrarNotificacion(index)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid className={classes.messageHeader}>
                                    <Typography style={{ fontSize: 16, fontWeight: 600, color: ' #487e4c', marginLeft: 10, marginTop: -25}} display="inline">
                                        {notificacion.notificacionTitulo}
                                    </Typography>
                                </Grid>
                                <Typography style={{ color: '#656565', fontSize: '12px', fontWeight: 400, marginLeft: 10, marginTop: -10, marginBottom: 20 }}>
                                    {notificacion.notificacionMensaje} {" index = "+ index + "  lengitud = "+(leido.length-1)}
                                </Typography>
                                { index !== leido.length-1 ?
                                    <Divider />
                                :
                                    <Fragment/>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Fragment >
        :
        <Fragment/>
    )

    return (
        <Fragment>
            <IconButton className={classes.botonHeaderSelect}
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                // posición inicial
                //onClick={handleToggle("bottom-end")}
                //onClick={handleToggle("bottom-start")}
                onClick={handleToggle("bottom")}
                transitioncomponent={Fade}
            >
                <Badge badgeContent={props.notificaciones.length} color="secondary" overlap="circular">
                    <NotificationsActiveIcon />
                </Badge>
            </IconButton>
            <Popper 
                open={open}
                anchorEl={anchorEl}
                // Funciona igual con cualquiera
                //placement={'bottom-start'}
                //placement={placement}
                transition
                disablePortal={false}
                // Impedir que se pinte encima/debajo de otros componentes (flip)
                // y que se desborde y pinte fuera de la ventana (preventOverflow)
                modifiers={{
                    flip: {
                        enabled: false,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window',
                    },
                }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    {miListaOpciones}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>
    )
};
