import React, { useState, Fragment } from 'react'
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Collapse from '@material-ui/core/Collapse';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

import BotonDrawer from '../botones/BotonDrawer.js'

import { createStore, useStore } from 'react-hookstore';

const drawerWidth = 240;


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },

    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: -drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    menuMain: {
        zIndex: "4000",
    },
    appBar: {
        marginTop: '80px',
        backgroundColor: '#1b5e20',

        marginRight: -drawerWidth,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,

    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        marginTop: '60px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    alignContent: {
        alignSelf: 'center'
    },
    listSubheader: {
        padding: 0,
        minWidth: 0,
        zIndex: 8000,
        color: '#757575'
    },
    listButton: {
        color: '#757575'
    },
    botonDrawer: {
        marginLeft: '10px',
        color: '#757575'
    }

}));

const ListItems = ({ items, visible, onClick }) => (
    <Collapse in={visible}>
        {items
            .filter(({ hidden }) => !hidden)
            .map(({ texto, habilitado, Icon, salto }, i) => (
                <ListItem
                    button
                    key={i}
                    component={Link}
                    to={salto}
                    disabled={!habilitado}
                    onClick={onClick(texto)}
                >
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                    <ListItemText>{texto}</ListItemText>
                </ListItem>
            ))}
    </Collapse>
)



createStore('drawerStore', false);

export default function DrawerGeroa({ ...props }) {
    const classes = useStyles();
    const [open, setOpen] = useStore('drawerStore');
    const theme = useTheme();
    
   

    /* importante para abrir/cerrar botón servicios añadir sections + nombre grupo. 
        <ListItems
           items={elemento.subMenu}
           visible={sections.servicios}
           onClick={onClick}
        />
    */
    const [sections, setSections] = useState({

     })

    const onClick = content => () => {
        setOpen(false);
        setSections(false);
    }

    const toggleSection = name => () => {
        setSections({ ...sections, [name]: !sections[name] });
    };

    function handleDrawerClose() {
        setOpen(false);
    }



    // Lista de opciones
    let miListaOpciones = props.opciones.map((elemento, i) => (
        elemento.nombre === undefined ?
            <Fragment key={i}> 
                <BotonDrawer
                    disabled={!elemento.habilitado}
                    to={elemento.salto}
                    texto={elemento.texto}
                    onClick={() => setOpen(false)}
                    className={classes.botonDrawer}
                />
                <br />
            </Fragment> 
        :
            <Fragment key={i}> 
                <ListItems
                    visible = {sections[elemento.nombre]}
                    items={elemento.subMenu}
                    onClick={onClick}
                 />
                 <ListSubheader>
                    <Button
                        disableRipple
                        classes={{ root: classes.listSubheader }}
                        onClick={toggleSection(elemento.nombre)}
                        >
                        {elemento.texto}
                     </Button>
                 </ListSubheader>
            </Fragment>
    ))



    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>

                    {miListaOpciones}

               </List>
            </Drawer>
        </div >
    );
};