import React, { Fragment } from "react";
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles'
import ThemeGeroa from '../../components/ThemeGeroa.js'
import { withTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    registro: {

        marginTop: '20px',
        minHeight: '65vh'
    },
    boton: {
        color: '#FFF',
        backgroundColor: '#1b5e20',
        textTransform: "none",
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "#4caf50",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
    },
}));



function abreCorreo(event, email) { 
    event.stopPropagation();
    event.preventDefault();
    // Abrir el programa de correo electrónico. //
    window.location = "mailto:"+ email
}




const Cookies = ({t}) => {
    const classes = useStyles();
    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Fragment>
                <CssBaseline />
                <Container maxWidth="md" className={classes.registro} >
                    <Typography align="center" style={{ fontSize: '32px', color: '#487e4c', fontWeight: 700, marginTop: 20, marginBottom: 18 }}>
                        {t("Cookies.L1")}
                    </Typography>
                    <Grid item style={{ marginTop: 1, marginBottom: 1 }}>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Cookies.L2")}
                        </Typography>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L3")}
                        </Typography>
                        <br/>
                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                            {t("Cookies.L4")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                             {t("Cookies.L5")}
                        </Typography>

                        <Typography style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                             <a href="https://www.sareko.net" target="_blank" rel="noopener noreferrer">
                                {"https://www.sareko.net"}
                             </a>
                        </Typography>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                            {t("Cookies.L6")}
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L7")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L8")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20 }}>
                            {t("Cookies.L9")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:20  }}>
                            {t("Cookies.L10")}
                        </Typography>
                         <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L11")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L12")}
                        </Typography>

                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="https://policies.google.com/privacy?hl=es" target="_blank" rel="noopener noreferrer">
                                {"https://policies.google.com/privacy?hl=es"}
                             </a>
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                            {t("Cookies.L13")}
                        </Typography>
                        <Typography display="inline"
                                    onClick={e => abreCorreo(e, "admin@ekolurra")}
                                    style={{ color: 'blue',
                                              fontSize: '16px',
                                              fontWeight: 400,
                                              textDecoration:'underline',
                                              }}>
                            {"admin@ekolurra.eus"}
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align= "justify" style={{  fontStyle: 'italic', color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L14")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L15")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{ color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L16")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="http://www.google.com/intl/ca/policies/privacy" target="_blank" rel="noopener noreferrer">
                                {"http://www.google.com/intl/ca/policies/privacy"}
                             </a>
                        </Typography>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="http://www.google.com/intl/en/privacypolicy.html" target="_blank" rel="noopener noreferrer">
                                {"http://www.google.com/intl/en/privacypolicy.html"}
                             </a>
                        </Typography>
                        <br/>

                        <Typography style={{ color: '#487e4c', fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                             {t("Cookies.L17")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                              {t("Cookies.L18")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L19")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L20")}
                        </Typography>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">
                                {"https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"}
                             </a>
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L21")}
                        </Typography>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="https://support.microsoft.com/es-es/help/4027947/microsoft-edge-delete-cookies" target="_blank" rel="noopener noreferrer">
                                {"https://support.microsoft.com/es-es/help/4027947/microsoft-edge-delete-cookies"}
                             </a>
                        </Typography>
                        <br/>
                         <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L22")}
                        </Typography>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer">
                                {"https://support.google.com/chrome/answer/95647?hl=es"}
                             </a>
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L23")}
                        </Typography>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="noopener noreferrer">
                                {"http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"}
                             </a>
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L24")}
                        </Typography>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">
                                {"https://support.apple.com/es-es/guide/safari/sfri11471/mac"}
                             </a>
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L25")}
                        </Typography>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="http://support.apple.com/kb/ht1677?viewlocale=es_es&locale=es_es" target="_blank" rel="noopener noreferrer">
                                {"http://support.apple.com/kb/ht1677?viewlocale=es_es&locale=es_es"}
                             </a>
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L26")}
                        </Typography>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                             <a href="https://support.google.com/chrome/answer/2392971?hl=es" target="_blank" rel="noopener noreferrer">
                                {"https://support.google.com/chrome/answer/2392971?hl=es"}
                             </a>
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                            {t("Cookies.L27")}
                        </Typography>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                             <a href="http://www.aboutcookies.org " target="_blank" rel="noopener noreferrer">
                                {"http://www.aboutcookies.org "}
                             </a>
                        </Typography>
                        <Typography align= "justify" style={{color: 'blue', fontSize: '16px', fontWeight: 400, marginBottom: 8, marginLeft:5 }} display="inline">
                             <a href="http://www.aboutcookies.org/Default.aspx?page=1" target="_blank" rel="noopener noreferrer">
                                {"http://www.aboutcookies.org/Default.aspx?page=1"}
                             </a>
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }}>
                            {t("Cookies.L28")}
                        </Typography>
                        <br/>
                        <Typography align= "justify" style={{color: '#00233D', fontSize: '16px', fontWeight: 400, marginBottom: 8 }} display="inline">
                            {t("Cookies.L29")}
                        </Typography>
                        <Typography display="inline"
                                    onClick={e => abreCorreo(e, "admin@ekolurra")}
                                    style={{ color: 'blue',
                                              fontSize: '16px',
                                              fontWeight: 400,
                                              textDecoration:'underline',
                                              }}>
                            {"admin@ekolurra.eus"}
                        </Typography>
                         <br/>
                        <br/>   
                        <br/>           
                    </Grid>
                    <Grid item className={classes.plantilla}>

                    </Grid>
                </Container>
            </Fragment>
            
        </MuiThemeProvider >
    );
}

export default withTranslation()(Cookies)

