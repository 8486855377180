import React, { useState } from "react";

import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createStore, useStore } from 'react-hookstore';

import ThemeGeroa from '../../components/ThemeGeroa.js'

import BotonValidacion from '../../components/botones/BotonValidacion'

import { withTranslation } from 'react-i18next'
import { listUserEmail } from "../../apis/api-user.js"
import { enviaEmailPassword } from '../../apis/api-email'

import { CadenaAleatoria } from "../../config/funcionesPropias.js";



const useStyles = makeStyles(theme => ({
    registro: {
        marginTop: '0px',
        minHeight: '65vh'
    },
    mensajeHelper: {
        color: 'rgba(0, 0, 0, 0.54)',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-6px',
        minHeight: '1em',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },
    mensajeError: {
        color: '#ff3878',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-6px',
        minHeight: '1em',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },
    textField: {
        marginBottom: 12,
    },
    nounderline: {
        textDecoration: 'none',
        cursor: 'pointer',
    }
}));

createStore('DatosPass')

const Password = ({t}) => {
    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');
    const classes = useStyles();

    //const [salto, setSalto] = useState('')
  
    const [values, setValues] = useStore('DatosPass');
    const [errors, setErrors] = useState({})

    if (values.usuario === undefined) {
        values.usuario = ''
    }

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    }

  
    // Valida únicamente la sintaxis de los 2 campos (usuario y password)
    const validate = (values) => {
        if (values.usuario === '') {
            errors.usuario = t('El usuario es obligatorio')
        }
            else {
            errors.usuario = '';
        }
        return errors
    }

    
    async function handleSubmit(event) {
        event.preventDefault()

        // Valida que se haya rellenado el formulario
        setErrors(validate(values))
        setValues({ ...values, usuario: values.usuario});
         
        if (errors.usuario === ''){
            try {
                // Llama al Api para enviar un mail
                const data = await listUserEmail({usuario: values.usuario})

                console.log('data = ', data)

                if (data.length === 0) {
                    throw t('El Usuario no es correcto')
                } else {
                    let tp = CadenaAleatoria(10)
                    //
                    setValuesGlobales({ ...valuesGlobales,
                        claveCambioPassword: tp,
                        usuarioCambioPassword: data[0].CliUsu
                    })
                    // Enviar un correo al usuario
                    const dataP = await enviaEmailPassword(data[0].CliEma, tp)
                    if (dataP === '') {
                        throw t('El Email no es correcto')
                    } else {
                         errors.usuario = ''
                    }
                }
            }
            catch (err) {
                errors.usuario = err
            }
            // Actualizar errores.
            setErrors(errors)
            // Necesario para que pinte en pantalla si hay error. 
            setValues({ ...values, usuario: values.usuario});

        }
        return errors.usuario === '' ? true : false
    }


    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Container maxWidth="md" className={classes.registro}>
                <Typography style={{ fontSize: '32px', color: '#00233D', fontWeight: 600, marginTop: 10, marginBottom: 10 }}>
                    {t('OlvidoPassword')}
                </Typography>
                  <Grid item xs={7}>
                    <Typography  align= "justify" style={{ fontSize: '14px', color: ThemeGeroa.palette.primary.main, fontWeight: 400, marginTop: 10, marginBottom: 18 }}>
                            {t('TextoOlvidoPassword')}
                    </Typography>
                    <br/>
                    <TextField
                        type="usuario"
                        required
                        label={t("Usuario")}
                        value={values.usuario}
                        className={classes.textField}
                        fullWidth
                        onChange={handleChange('usuario')}
                    />
                    {(() => {
                        if (errors.usuario === undefined || errors.usuario === '') {
                            return <Typography className={classes.mensajeHelper}>
                                {t('Introduzca su Usuario')}
                            </Typography>
                        } else {
                            return <Typography className={classes.mensajeError}>{errors.usuario}</Typography>
                        }
                    })()}
                    <br/>
                    <br/>
                    <br/>
                    <Grid container>
                        <BotonValidacion
                            texto={t('Aceptar')}
                            salto={"/NuevoPassword/"}
                            validacion={handleSubmit}
                        />
                    </Grid>
                </Grid>
            </Container>
         </MuiThemeProvider >
    );
}

export default withTranslation()(Password)