import React, { useState } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const WEEKDAYS_SHORT = {
  es: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
  eu: ['Ig','Al','As','Az','Og','Or','Lr'],
};
const MONTHS = {
  es: [ 'Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Setiembre','Octubre','Noviembre','Diciembre' ],
  eu: [ 'Urtarrila','Otsaila','Martxoa','Apirila','Maiatza','Ekaina','Uztaila','Abuztua','Iraila','Urria','Azaroa','Abendua' ],
};

const WEEKDAYS_LONG = {
  es: ['Domigo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'],
  eu: ['Igandea','Astelehena','Asteartea','Asteazkena','Osteguna','Ostirala','Larunbata'],
};

const FIRST_DAY_OF_WEEK = {
  es: 1,
  eu: 1,
};
// Translate aria-labels
const LABELS = {
  es: { nextMonth: 'Próximo mes', previousMonth: 'Mes anterior' },
  eu: { nextMonth: 'Datorren hilean', previousMonth: 'Aurreko hilabetea' },
};


const SelectorDias = props => {
  const idioma = props.idioma // vG.idioma ('Es' 'Eus')
  
  // Estructura array fechas: [{elemento: FechaLargaEsp(), seleccion: true/false, fecha: new Date()}]
  // Fecha entrega Inicial. //
  //let index = props.fechas.findIndex( f => f.seleccion )
  //let fInicio = props?.fechas[index]?.fecha || props?.fechas[0]?.fecha || new Date()
  const fechasEntrega = props.fechas
                        //.filter( f => f.fecha > fInicio )
                        .map( f => f.fecha )
  const [diasRepe, setdiasRepe] = useState(props.repite.map( f => f.fecha ))

  const desactivados = []
  for (let i=0, l=fechasEntrega.length; i < l; ++i) {
      switch (i) {
        // primero. //
        case 0:
          desactivados.push( {before: fechasEntrega[i]} )
          break
        // ultimo. //
        case l-1:
          desactivados.push( {after: fechasEntrega[i]} )
          break
        default:
          break
      }
      // Todos excepto el primero. //
      if (i !== 0) {
          desactivados.push( {after: fechasEntrega[i-1],
                              before: fechasEntrega[i]} )
      }
  }

  const modifiers = {
    conEntrega: fechasEntrega,
    disabled: desactivados         // "disabled": Nombre obligatorio para disabledDays
  };
  const modifiersStyles = {
    conEntrega: {
      /*background-color: green,*/
      color: 'orange',
      fontWeight: 800,
    },
    disabled: {
    /*background-color: white,*/
    color: 'grey',
    /*font-weight: 200,*/
    },
  }

  let mes = {} // {inicio: fecha, desde: fecha, hasta: fecha}
  let fecha = new Date()
  mes.inicio = new Date(fecha.getFullYear(), fecha.getMonth())
  fecha = fechasEntrega[0] || fecha
  mes.desde = new Date(fecha.getFullYear(), fecha.getMonth())
  fecha = fechasEntrega[fechasEntrega.length-1] || fecha
  mes.hasta = new Date(fecha.getFullYear(), fecha.getMonth())


  function handleDayClick(day, { selected, disabled }) {
    let dias = [...diasRepe]
    if (disabled) {
       return
    }
    if (selected) {
      const selectedIndex = dias.findIndex( selectedDay => DateUtils.isSameDay(selectedDay, day) )
      dias.splice(selectedIndex, 1);
    } else {
      dias.push(day);
    }
    setdiasRepe( [...dias] );
    // Cambiar fechas en origen. //
    props.accion(day);
  }


  return (
      <div>
        <DayPicker
            // idioma. //
            locale={idioma}
            months={MONTHS[idioma]}
            weekdaysLong={WEEKDAYS_LONG[idioma]}
            weekdaysShort={WEEKDAYS_SHORT[idioma]}
            firstDayOfWeek={FIRST_DAY_OF_WEEK[idioma]}
            labels={LABELS[idioma]}

            //showOutsideDays
            // mes inicio, desde-hasta. //
            initialMonth={mes.inicio}
            fromMonth={mes.desde}
            toMonth={mes.hasta}

            //disabledDays={desactivados}
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
            
            selectedDays={diasRepe}
            onDayClick={handleDayClick}

            //todayButton="Finalizar"
            //onTodayButtonClick={(open) => setOpen(!open)}
   
        />
      </div>  )
}

export default SelectorDias