
import config from './../config/config.js'

const rutaApi = config.miServidor+'/api/notificacion'

const listaNotificacionesUsuario = (params, credentials) => {
  return fetch(rutaApi+'/notificacionesUsuario/'+params.usuarioEmail, {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
      //'Accept': 'application/json',
      //'Authorization': 'Bearer ' + credentials.t
    }
  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))
}





export {
  listaNotificacionesUsuario
}
